import React from "react";
import PropTypes from "prop-types";
import { brand, mq } from "../../../styles"
import styled, { css } from "styled-components";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby"
import { pop } from "../../../../content/menu";

const BioCard = styled.div`
    width: 250px;
    @media screen and ${mq.maxMd} {
        margin: 0 auto 5%;
        
      }
    .bio-tile {
        cursor: pointer;

        .bio-tile-bg {
            filter: grayscale(1);
            position: relative;
            .bio-tile-hover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 250px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0,0,0,.8);
                opacity: 0;
                transition: .3s ease-in-out opacity;
            }

            &:hover {
                .bio-tile-hover {
                    opacity: 1;
                }
            }
        }
    }
    .bio-tile-details {
        margin-top: 10px;
        font-weight: 700;
        span {
            display: block;
        }
        span:first-of-type{
            font-size: 20px;
            line-height: 22px;
            color: ${brand.colors.pg_orange}
        }

        span:last-of-type {
            font-size: 16px;
            line-height: 24px;
            color: ${brand.colors.pg_primary_dark}
        }
    }

    .bio-popup {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.8);

        .bio-popup-inner {
            position: fixed;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -40%);
            display: flex;
            width: 95%;
            max-width: 1400px;
            min-height: 400px;
            height: auto;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            background-color: #fff;
            margin-bottom: -5px;
            @media screen and ${mq.maxMd} {
                display: block;
                max-width: 400px;
                height: 100%;
                max-height: 710px;
                overflow: scroll;
              }
              
            .popup-image{
                width: 400px;
                height:400px;
                @media screen and ${mq.maxMd} {
                    margin: auto;
                }
                @media screen and (max-width: 450px) {
                width: 100%;
                height: 310px;
                }

                .gatsby-image-wrapper {
                    @media only screen and (max-width: 450px) {
                        height: 310px !important;
                    }
                }
            }

            .popup-details {
                margin-top: 15px;
                padding: 0 0 0 20px;

                @media only screen and ${mq.maxMd} {
                    padding: 0;
                    width: 95%;
                    margin: 15px auto 0;
                }

                span {
                    font-size: 26px;
                    font-weight: 600;

                    @media only screen and (max-width: 450px) {
                        font-size: 22px;
                    }
                }
                span:first-of-type {
                    color: ${brand.colors.pg_orange};
                }
                span:last-of-type {
                    color: ${brand.colors.pg_primary_dark};
                }
                p{  margin-top: 10px;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                    color: ${brand.colors.pg_primary_dark};
                    white-space: pre-wrap;
                    margin-bottom: 1vw;
                    @media only screen and (max-width: 1050px) {
                        font-size: 14px;
                    }
                }
            }

            #modal-close {
                font-size: 30px;
                font-weight: 900;
                width: 30px;
                height: 30px;
                color: ${brand.colors.pg_primary_dark};
                cursor: pointer;
                align-self: flex-start;
                margin-right: 10px;
                position: relative;

                &:hover {
                    color: ${brand.colors.pg_orange};
                }
                @media screen and ${mq.maxMd} {
                    position: absolute;
                    top: 1%;
                    right: 0%;
                    background-color: #fff;
                    border: 2px solid ${brand.colors.pg_primary_dark};
                    border-radius: 26px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 2px;
                }
            }
        }
    }
`;

const ExecutiveBio = ({name, title, blurb, profileIcon, profilePopup, linkedin, fr, ...props}) => {
    const data = useStaticQuery(graphql`
        query {
            linkedinIcon: file(base: { eq: "linkedin.png" }) {
                childImageSharp {
                    fixed(width: 50, height: 50, webpQuality: 100){
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `);

    const tileImage = <GatsbyImage fixed={profileIcon.childImageSharp.fixed} />;
    const popupImage = <GatsbyImage fixed={profilePopup.childImageSharp.fixed} />;
    const popUpID = name.split(" ")[0] + "-" + name.split(" ")[1];
    const modalID = popUpID + "-modal";
    function openModal(){
        document.getElementById(modalID).style.display = "block";
    }

    function closeModal() {
        
        let modals = document.getElementsByClassName("bio-popup");
        for (let x = 0; x < modals.length; x++) {
            modals[x].style.display = "none";
        }
    
    }


    return(
        <BioCard>
            <div className="bio-tile" id={popUpID} onClick={()=>openModal(popUpID)}>
                <div className="bio-tile-bg">
                   {tileImage}
                   <div className="bio-tile-hover">
                    <span>{ fr ? 'Cliquez pour en savoir plus' : 'Click to read more'}</span>
                   </div>
                </div>
                <div className="bio-tile-details">
                    <span>
                        {name}
                    </span>
                    <span>
                        {title}
                    </span>
                </div>
            </div>

            <div className="bio-popup" id={popUpID+"-modal"} onClick={()=>closeModal(popUpID+"-modal")}>
                <div className="bio-popup-inner">
                    <div className="popup-image">
                        {popupImage}
                    </div>
                    <div className="popup-details">
                        <span>{name}</span>
                        &nbsp;&nbsp;
                        <span>{title}</span>
                        <p>{blurb}</p>
                        <a href={linkedin} style={{cursor: "pointer"}} target="_blank" rel="noreferer"><GatsbyImage fixed={data['linkedinIcon'].childImageSharp.fixed} /></a>
                    </div>
                    <div id={"modal-close"} onClick={closeModal}>x</div>
                </div>
                
            </div>

        </BioCard>
    )
}

export default ExecutiveBio;

export const query = graphql`
  fragment BioTileImage on File {
    childImageSharp {
      fixed(width: 250, height: 250, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
      }
    }
  },
  fragment BioPopupImage on File {
    childImageSharp {
      fixed(width: 400, height: 400, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;

