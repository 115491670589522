import import_nunito from "./fonts/nunito.font";
import import_sherika from "./fonts/sherika.font";

export const brand = {
  gradient: "linear-gradient(225deg, #4DC9EE 0%, #286FF7 100%)",
  dropShadow: "0 0 11px 0 #0002",
  colors: {
    pts_blue_dark: "#286FF7",
    pts_blue_light: "#40c8f4",
    pts_pink: "#eb0055",
    main_background: "#FFFFFF",
    second_background: "#f5f5f7",
    pg_primary_dark: "#000414",
    pg_blue: "#1B64EF",
    pg_orange: "#F74E01",
    pg_primary_light: "#F6F6F6",
    pg_secondary_dark: "#828282",
    pg_tertiary_medium: "#C4C7CC",
  },
};

export const font = {
  family: {
    nunito: `'Nunito', Helvetica Neue, sans-serif`,
    sherika: `'Sherika', Helvetica Neue, sans-serif`,
  },
  imports: {
    nunito: import_nunito,
    sherika: import_sherika,
  },
};

export const size = {
  xs: "480px",
  sm: "768px",
  md: "992px",
  lg: "1200px",
  xl: "1400px",
};

export const mq = {
  minXs: `(min-width: ${size.xs})`,
  minSm: `(min-width: ${size.sm})`,
  maxSm: `(max-width: ${size.sm})`,
  minMd: `(min-width: ${size.md})`,
  maxMd: `(max-width: ${size.md})`,
  minLg: `(min-width: ${size.lg})`,
  minXl: `(min-width: ${size.xl})`,
};
