import { css } from "styled-components";
import { withPrefix } from "gatsby";

let basePath = '/fonts/';

try {
  basePath = withPrefix('/fonts/');
} catch (error) {
  // ReferenceError: __BASE_PATH__ is not defined
  // console.log(error);
}

const sherika = css`

@font-face {
	font-family: 'Sherika';
	font-style: normal;
    font-weight: 400;
	font-display: swap;
	src: url(${basePath}sherika/Sherika-Regular.ttf);
}

@font-face {
	font-family: 'Sherika';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: url(${basePath}sherika/Sherika-Bold.ttf);
	
}

@font-face {
	font-family: 'Sherika';
	font-weight: 900;
	font-style: normal;
	font-display: swap;
	src: url(${basePath}sherika/Sherika-Black.ttf);
	
}
`;

export default sherika;
