module.exports = [
  {
    title: 'Products',
    url: '/products/'
  },
  {
    title: 'Industries',
    submenu: [
      {
        title: 'Air',
        url: '/industries/air/',
        labelIcon: 'air'
      },
      {
        title: 'Hospitality',
        url: '/industries/hospitality/',
        labelIcon: 'hospitality'
      },
      {
        title: 'Cruise',
        url: '/industries/cruise/',
        labelIcon: 'cruise'
      },
      {
        title: 'Rail',
        url: '/industries/rail/',
        labelIcon: 'rail'
      },
      { 
        title: 'Loyalty Solutions',
        url: 'https://www.points.com/',
        icon: "external",
        labelIcon: 'loyalty',
        target: "_blank"
      },
    ]
  },
  {
    title: 'Partners',
    url: '/partners/'
  },
  {
    title: 'About',
    url: '/about/'
  },
  {
    title: 'Careers',
    url: '/careers/'
  },
  {
    title: 'Request a Demo',
    url: '/contact/request-a-demo/'
  }
];
