import React from "react"


const PGLogo = () => {
    return(
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 554.7 92">
<g id="Layer_2" className="st0">
	<polygon className="st1" points="-10,60.3 10,80.3 10,60.3 	"/>
	<polygon className="st1" points="81.8,57.7 101.8,77.7 101.8,57.7 	"/>
	<polygon className="st1" points="136.1,55.9 156.1,75.9 156.1,55.9 	"/>
	<polygon className="st1" points="66.9,-10 91,8.9 66.3,8.9 	"/>
	<polygon className="st1" points="62.7,70.3 72.7,80.3 72.7,64.7 	"/>
	<polygon className="st1" points="124.4,8.9 134.4,18.9 124.4,18.9 	"/>
	<polygon className="st1" points="233.9,8.9 243.9,18.9 233.9,18.9 	"/>
	<polygon className="st1" points="277.9,8.9 287.9,18.9 277.9,22.3 	"/>
	<polygon className="st1" points="253.7,70.3 263.7,80.3 263.7,70.3 	"/>
	<polygon className="st1" points="256.6,8.9 266.6,18.9 256.6,18.9 	"/>
	<polygon className="st1" points="387.9,-10 397.9,0 387.9,0 	"/>
	<polygon className="st1" points="384.9,70.3 394.9,80.3 394.9,70.3 	"/>
	<polygon className="st1" points="326.4,70.3 336.4,80.3 336.4,70.3 	"/>
	<polygon className="st1" points="521.3,-9.9 564.7,33.5 521.3,33.5 	"/>
	<polygon className="st1" points="474.5,36.9 517.9,80.3 517.9,33.5 474.5,33.5 	"/>
	<polygon className="st1" points="531.3,46.9 541.3,56.9 531.3,56.9 	"/>
</g>
<g id="Layer_1" className="st0">
	<g className="st2">
		<g>
			<path className="st3" d="M-10-10h27.5c4.9,0,9.1,0.6,12.7,1.9c3.6,1.3,6.6,3,8.9,5.3s4.1,4.9,5.3,8c1.2,3.1,1.8,6.3,1.8,9.9
				c0,3.6-0.6,6.9-1.8,9.8c-1.2,3-3,5.5-5.4,7.7s-5.4,3.8-9,5c-3.6,1.2-7.8,1.8-12.6,1.8h-13v21H-10V-10z M16.9,27.8
				c5,0,8.7-1.1,11.2-3.4c2.4-2.2,3.7-5.4,3.7-9.4c0-4-1.2-7.1-3.6-9.4C25.8,3.3,22,2.2,16.9,2.2H4.6v25.6H16.9z"/>
			<path className="st3" d="M52.7-10h14.3v70.3H52.7V-10z"/>
			<path className="st3" d="M108,50.8c-1.5,3.1-3.5,5.8-6.1,7.9c-2.6,2.1-5.7,3.2-9.3,3.2c-2.5,0-4.8-0.5-6.8-1.4c-2-0.9-3.7-2.2-5-3.8
				c-1.4-1.6-2.4-3.5-3.1-5.8c-0.7-2.2-1-4.7-1-7.3V8.9H91V40c0,2.9,0.8,5.3,2.3,7c1.5,1.8,3.7,2.6,6.5,2.6c1.4,0,2.8-0.3,4-0.8
				c1.2-0.6,2.3-1.3,3.3-2.2c0.9-0.9,1.7-2,2.2-3.2c0.6-1.2,0.8-2.5,0.8-3.8V8.9h14.3v51.5h-13l-1.3-9.6H108z"/>
			<path className="st3" d="M165.8,23.5c-0.8-2.1-2.1-3.7-4.1-4.7c-2-1-4.1-1.5-6.3-1.5c-2,0-3.8,0.5-5.4,1.5c-1.7,1-2.5,2.4-2.5,4.3
				c0,1.6,0.7,2.8,2.1,3.6c1.4,0.7,3.2,1.4,5.6,1.9c2.1,0.5,4.4,1,7,1.6c2.6,0.5,5,1.3,7.3,2.4c2.3,1.1,4.2,2.6,5.8,4.5
				c1.6,2,2.3,4.6,2.3,8c0,2.8-0.6,5.2-1.8,7.3c-1.2,2.1-2.8,3.8-4.8,5.2c-2.1,1.4-4.5,2.5-7.3,3.2c-2.8,0.7-5.8,1.1-9,1.1
				c-7.2,0.1-12.8-1.5-17-4.6c-4.2-3.2-6.6-7.7-7.1-13.5h11.5c0.4,1.4,1,2.7,1.9,3.7c0.8,1,1.8,1.9,2.9,2.6c1.1,0.7,2.3,1.2,3.7,1.5
				c1.3,0.3,2.7,0.5,4,0.5c2.4,0,4.5-0.5,6.3-1.5c1.8-1,2.6-2.4,2.6-4.4c0-1.8-0.7-3.1-2.2-3.8c-1.5-0.7-3.5-1.4-6-2
				c-2-0.5-4.3-1-6.8-1.5s-4.9-1.3-7.1-2.3c-2.2-1-4.1-2.5-5.6-4.4c-1.5-1.9-2.3-4.5-2.3-7.7c0-2.7,0.6-5.1,1.7-7.2
				c1.1-2.1,2.7-3.9,4.7-5.3c2-1.5,4.3-2.6,7-3.3c2.7-0.7,5.6-1.1,8.6-1.1c5.7,0,10.5,1.4,14.4,4.1c3.9,2.8,6.2,6.8,7.1,12H165.8z"
				/>
			<path className="st3" d="M196.8,64.7c0.7,2,2.1,3.6,4.1,4.8c2,1.2,4.2,1.8,6.7,1.8c4,0,7-1.4,9.1-4.1c2.1-2.8,3.1-6.2,3.1-10.4
				l-0.1-6.5h-2.1c-0.8,3.5-2.6,6.3-5.1,8.5c-2.6,2.1-5.7,3.2-9.2,3.2c-3.1,0-5.9-0.6-8.4-1.9c-2.5-1.3-4.7-3.1-6.5-5.4
				s-3.2-5.1-4.2-8.4c-1-3.3-1.5-7-1.5-11c0-4.1,0.5-7.9,1.6-11.3c1-3.4,2.5-6.3,4.3-8.8c1.9-2.4,4.1-4.3,6.6-5.7
				c2.6-1.3,5.4-2,8.5-2c3.5,0,6.4,1,8.8,2.9c2.4,1.9,4.1,4.7,5.1,8.4h2.1l1.3-9.8h13v47.9c0.1,8.2-2.1,14.5-6.6,18.8
				c-4.5,4.3-11.2,6.5-20.1,6.5c-2.9,0-5.7-0.4-8.4-1.1c-2.6-0.7-5-1.9-7.1-3.3c-2.1-1.5-3.8-3.3-5.2-5.4c-1.4-2.1-2.4-4.6-2.9-7.4
				H196.8z M208.3,19.7c-3.5,0-6.2,1.4-8.3,4.1c-2.1,2.7-3.1,6.3-3.1,10.8c0,4.5,1,8.1,3.1,10.8c2.1,2.7,4.9,4.1,8.3,4.1
				c3.3,0,6-1.3,8.2-4s3.2-6.2,3.2-10.8c0-2.2-0.3-4.2-0.8-6c-0.6-1.9-1.3-3.5-2.3-4.8c-1-1.4-2.2-2.4-3.6-3.2S210,19.7,208.3,19.7z
				"/>
			<path className="st3" d="M277.9,22.3H271c-1.7,0-3.3,0.3-4.9,1c-1.6,0.7-3,1.6-4.2,2.7s-2.2,2.6-2.9,4.3c-0.7,1.7-1.1,3.6-1.1,5.8
				v24.2h-14.2V8.9h12.9l1.3,11h2.1c1.2-3.7,2.8-6.5,4.8-8.3c2-1.8,4.5-2.7,7.6-2.7h5.6V22.3z"/>
			<path className="st3" d="M315.5,27.4c0-3.3-0.9-5.7-2.6-7.2c-1.8-1.5-4.1-2.2-7.1-2.2c-2.7,0-4.9,0.7-6.8,2.1c-1.9,1.4-3,3.3-3.6,5.8
				H283c0.5-3.1,1.5-5.8,3-8.2c1.5-2.3,3.3-4.2,5.4-5.7s4.5-2.6,7.2-3.4c2.6-0.7,5.4-1.1,8.3-1.1c3.1,0,6.1,0.4,8.8,1.2
				c2.7,0.8,5.1,2,7.2,3.6s3.7,3.7,4.8,6.2c1.2,2.5,1.8,5.5,1.8,8.9v33h-13l-0.9-7.9h-2c-2,3.1-4.4,5.5-7.1,7
				c-2.7,1.6-6,2.3-9.9,2.3c-4.4,0-7.9-1.2-10.7-3.7c-2.8-2.5-4.2-6-4.2-10.5c0-3.5,0.8-6.3,2.4-8.7c1.6-2.3,3.7-4.2,6.3-5.7
				c2.6-1.4,5.6-2.5,8.9-3.1c3.4-0.6,6.8-0.9,10.2-0.9h6V27.4z M315.5,36.8h-5.7c-2,0-3.8,0.2-5.5,0.5c-1.7,0.3-3.2,0.8-4.5,1.5
				c-1.3,0.7-2.3,1.5-3.1,2.5c-0.7,1-1.1,2.2-1.1,3.6c0,2.1,0.7,3.7,2.2,4.8c1.5,1.1,3.3,1.7,5.4,1.7c1.8,0,3.4-0.3,4.9-0.8
				c1.5-0.5,2.8-1.3,3.9-2.3c1.1-1,2-2.1,2.6-3.4c0.6-1.3,0.9-2.7,0.9-4.2V36.8z"/>
			<path className="st3" d="M387.9,60.3h-13l-1.3-10.2h-2.1c-1.2,3.6-3,6.4-5.4,8.5c-2.4,2.1-5.5,3.1-9.2,3.1c-3.1,0-5.8-0.7-8.4-2
				c-2.5-1.3-4.6-3.2-6.4-5.6c-1.8-2.4-3.1-5.3-4.1-8.7c-1-3.4-1.5-7.3-1.5-11.6c0-4,0.5-7.7,1.5-11c1-3.3,2.3-6.1,4.1-8.4
				c1.8-2.3,3.9-4.1,6.4-5.3c2.5-1.2,5.3-1.9,8.4-1.9c3.6,0,6.6,1,9,2.9c2.4,1.9,4.3,4.6,5.6,8.2h2.1V-10h14.3V60.3z M362.3,19.7
				c-3.5,0-6.2,1.4-8.3,4.1c-2.1,2.7-3.1,6.3-3.1,10.8c0,4.5,1,8.1,3.1,10.8c2.1,2.7,4.9,4.1,8.3,4.1c3.4,0,6.1-1.3,8.2-4
				c2.1-2.6,3.1-6.2,3.1-10.8c0-2.2-0.3-4.2-0.8-6.1c-0.6-1.8-1.3-3.4-2.3-4.7c-1-1.3-2.2-2.4-3.6-3.1
				C365.5,20.1,364,19.7,362.3,19.7z"/>
			<path className="st3" d="M447,43.3c-0.8,2.8-2.1,5.3-3.7,7.6c-1.6,2.3-3.5,4.2-5.8,5.8c-2.2,1.6-4.6,2.8-7.3,3.7
				c-2.6,0.9-5.4,1.3-8.3,1.3c-4,0-7.7-0.7-11-2c-3.3-1.3-6.1-3.1-8.5-5.5c-2.3-2.4-4.2-5.2-5.4-8.5c-1.3-3.3-1.9-7-1.9-11
				c0-4.1,0.6-7.8,1.9-11.2c1.3-3.4,3.1-6.2,5.4-8.6c2.3-2.4,5.2-4.2,8.5-5.6c3.3-1.3,7-2,11-2c3.9,0,7.4,0.7,10.6,2
				c3.2,1.3,5.8,3.3,8.1,5.8c2.2,2.5,3.9,5.5,5.1,9c1.2,3.5,1.8,7.4,1.8,11.7V38h-38.9c0.4,4,1.8,7.1,4.2,9.5
				c2.4,2.4,5.5,3.6,9.3,3.6c2.6,0,4.9-0.7,7-2c2.1-1.4,3.6-3.3,4.7-5.7H447z M434.8,30.7c-0.3-3.7-1.6-6.8-4-9.1
				c-2.3-2.4-5.2-3.6-8.7-3.6c-3.5,0-6.5,1.2-8.8,3.5c-2.4,2.3-3.8,5.4-4.3,9.2H434.8z"/>
		</g>
		<g>
			<polygon className="st4" points="521.3,13.5 521.3,-9.9 497.9,-9.9 497.9,13.5 474.5,13.5 474.5,36.9 488.2,36.9 509.6,15.5 
				531,36.9 544.7,36.9 544.7,13.5 			"/>
			<rect x="497.9" y="36.9" className="st4" width="23.4" height="23.4"/>
		</g>
	</g>
	<g className="st2">
		<g>
			<path className="st3" d="M10,10h27.5c4.9,0,9.1,0.6,12.7,1.9s6.6,3,8.9,5.3s4.1,4.9,5.3,8c1.2,3.1,1.8,6.3,1.8,9.9
				c0,3.6-0.6,6.9-1.8,9.8c-1.2,3-3,5.5-5.4,7.7s-5.4,3.8-9,5c-3.6,1.2-7.8,1.8-12.6,1.8h-13v21H10V10z M36.9,47.8
				c5,0,8.7-1.1,11.2-3.4c2.4-2.2,3.7-5.4,3.7-9.4c0-4-1.2-7.1-3.6-9.4c-2.4-2.3-6.1-3.4-11.3-3.4H24.6v25.6H36.9z"/>
			<path className="st3" d="M72.7,10h14.3v70.3H72.7V10z"/>
			<path className="st3" d="M128,70.8c-1.5,3.1-3.5,5.8-6.1,7.9c-2.6,2.1-5.7,3.2-9.3,3.2c-2.5,0-4.8-0.5-6.8-1.4c-2-0.9-3.7-2.2-5-3.8
				c-1.4-1.6-2.4-3.5-3.1-5.8c-0.7-2.2-1-4.7-1-7.3V28.9H111V60c0,2.9,0.8,5.3,2.3,7c1.5,1.8,3.7,2.6,6.5,2.6c1.4,0,2.8-0.3,4-0.8
				c1.2-0.6,2.3-1.3,3.3-2.2c0.9-0.9,1.7-2,2.2-3.2c0.6-1.2,0.8-2.5,0.8-3.8V28.9h14.3v51.5h-13l-1.3-9.6H128z"/>
			<path className="st3" d="M185.8,43.5c-0.8-2.1-2.1-3.7-4.1-4.7c-2-1-4.1-1.5-6.3-1.5c-2,0-3.8,0.5-5.4,1.5c-1.7,1-2.5,2.4-2.5,4.3
				c0,1.6,0.7,2.8,2.1,3.6c1.4,0.7,3.2,1.4,5.6,1.9c2.1,0.5,4.4,1,7,1.6c2.6,0.5,5,1.3,7.3,2.4c2.3,1.1,4.2,2.6,5.8,4.5
				c1.6,2,2.3,4.6,2.3,8c0,2.8-0.6,5.2-1.8,7.3c-1.2,2.1-2.8,3.8-4.8,5.2c-2.1,1.4-4.5,2.5-7.3,3.2c-2.8,0.7-5.8,1.1-9,1.1
				c-7.2,0.1-12.8-1.5-17-4.6c-4.2-3.2-6.6-7.7-7.1-13.5h11.5c0.4,1.4,1,2.7,1.9,3.7c0.8,1,1.8,1.9,2.9,2.6c1.1,0.7,2.3,1.2,3.7,1.5
				c1.3,0.3,2.7,0.5,4,0.5c2.4,0,4.5-0.5,6.3-1.5c1.8-1,2.6-2.4,2.6-4.4c0-1.8-0.7-3.1-2.2-3.8c-1.5-0.7-3.5-1.4-6-2
				c-2-0.5-4.3-1-6.8-1.5s-4.9-1.3-7.1-2.3c-2.2-1-4.1-2.5-5.6-4.4c-1.5-1.9-2.3-4.5-2.3-7.7c0-2.7,0.6-5.1,1.7-7.2
				c1.1-2.1,2.7-3.9,4.7-5.3c2-1.5,4.3-2.6,7-3.3c2.7-0.7,5.6-1.1,8.6-1.1c5.7,0,10.5,1.4,14.4,4.1c3.9,2.8,6.2,6.8,7.1,12H185.8z"
				/>
			<path className="st3" d="M216.8,84.7c0.7,2,2.1,3.6,4.1,4.8c2,1.2,4.2,1.8,6.7,1.8c4,0,7-1.4,9.1-4.1c2.1-2.8,3.1-6.2,3.1-10.4
				l-0.1-6.5h-2.1c-0.8,3.5-2.6,6.3-5.1,8.5c-2.6,2.1-5.7,3.2-9.2,3.2c-3.1,0-5.9-0.6-8.4-1.9c-2.5-1.3-4.7-3.1-6.5-5.4
				s-3.2-5.1-4.2-8.4c-1-3.3-1.5-7-1.5-11c0-4.1,0.5-7.9,1.6-11.3c1-3.4,2.5-6.3,4.3-8.8c1.9-2.4,4.1-4.3,6.6-5.7
				c2.6-1.3,5.4-2,8.5-2c3.5,0,6.4,1,8.8,2.9c2.4,1.9,4.1,4.7,5.1,8.4h2.1l1.3-9.8h13v47.9c0.1,8.2-2.1,14.5-6.6,18.8
				c-4.5,4.3-11.2,6.5-20.1,6.5c-2.9,0-5.7-0.4-8.4-1.1c-2.6-0.7-5-1.9-7.1-3.3c-2.1-1.5-3.8-3.3-5.2-5.4c-1.4-2.1-2.4-4.6-2.9-7.4
				H216.8z M228.3,39.7c-3.5,0-6.2,1.4-8.3,4.1c-2.1,2.7-3.1,6.3-3.1,10.8c0,4.5,1,8.1,3.1,10.8c2.1,2.7,4.9,4.1,8.3,4.1
				c3.3,0,6-1.3,8.2-4c2.1-2.6,3.2-6.2,3.2-10.8c0-2.2-0.3-4.2-0.8-6c-0.6-1.9-1.3-3.5-2.3-4.8c-1-1.4-2.2-2.4-3.6-3.2
				C231.6,40.1,230,39.7,228.3,39.7z"/>
			<path className="st3" d="M297.9,42.3H291c-1.7,0-3.3,0.3-4.9,1c-1.6,0.7-3,1.6-4.2,2.7s-2.2,2.6-2.9,4.3c-0.7,1.7-1.1,3.6-1.1,5.8
				v24.2h-14.2V28.9h12.9l1.3,11h2.1c1.2-3.7,2.8-6.5,4.8-8.3c2-1.8,4.5-2.7,7.6-2.7h5.6V42.3z"/>
			<path className="st3" d="M335.5,47.4c0-3.3-0.9-5.7-2.6-7.2c-1.8-1.5-4.1-2.2-7.1-2.2c-2.7,0-4.9,0.7-6.8,2.1c-1.9,1.4-3,3.3-3.6,5.8
				H303c0.5-3.1,1.5-5.8,3-8.2c1.5-2.3,3.3-4.2,5.4-5.7c2.1-1.5,4.5-2.6,7.2-3.4c2.6-0.7,5.4-1.1,8.3-1.1c3.1,0,6.1,0.4,8.8,1.2
				c2.7,0.8,5.1,2,7.2,3.6c2.1,1.6,3.7,3.7,4.8,6.2c1.2,2.5,1.8,5.5,1.8,8.9v33h-13l-0.9-7.9h-2c-2,3.1-4.4,5.5-7.1,7
				c-2.7,1.6-6,2.3-9.9,2.3c-4.4,0-7.9-1.2-10.7-3.7c-2.8-2.5-4.2-6-4.2-10.5c0-3.5,0.8-6.3,2.4-8.7c1.6-2.3,3.7-4.2,6.3-5.7
				s5.6-2.5,8.9-3.1c3.4-0.6,6.8-0.9,10.2-0.9h6V47.4z M335.5,56.8h-5.7c-2,0-3.8,0.2-5.5,0.5c-1.7,0.3-3.2,0.8-4.5,1.5
				c-1.3,0.7-2.3,1.5-3.1,2.5c-0.7,1-1.1,2.2-1.1,3.6c0,2.1,0.7,3.7,2.2,4.8c1.5,1.1,3.3,1.7,5.4,1.7c1.8,0,3.4-0.3,4.9-0.8
				c1.5-0.5,2.8-1.3,3.9-2.3s2-2.1,2.6-3.4c0.6-1.3,0.9-2.7,0.9-4.2V56.8z"/>
			<path className="st3" d="M407.9,80.3h-13l-1.3-10.2h-2.1c-1.2,3.6-3,6.4-5.4,8.5c-2.4,2.1-5.5,3.1-9.2,3.1c-3.1,0-5.8-0.7-8.4-2
				c-2.5-1.3-4.6-3.2-6.4-5.6c-1.8-2.4-3.1-5.3-4.1-8.7c-1-3.4-1.5-7.3-1.5-11.6c0-4,0.5-7.7,1.5-11c1-3.3,2.3-6.1,4.1-8.4
				c1.8-2.3,3.9-4.1,6.4-5.3c2.5-1.2,5.3-1.9,8.4-1.9c3.6,0,6.6,1,9,2.9c2.4,1.9,4.3,4.6,5.6,8.2h2.1V10h14.3V80.3z M382.3,39.7
				c-3.5,0-6.2,1.4-8.3,4.1c-2.1,2.7-3.1,6.3-3.1,10.8c0,4.5,1,8.1,3.1,10.8c2.1,2.7,4.9,4.1,8.3,4.1c3.4,0,6.1-1.3,8.2-4
				c2.1-2.6,3.1-6.2,3.1-10.8c0-2.2-0.3-4.2-0.8-6.1c-0.6-1.8-1.3-3.4-2.3-4.7c-1-1.3-2.2-2.4-3.6-3.1
				C385.5,40.1,384,39.7,382.3,39.7z"/>
			<path className="st3" d="M467,63.3c-0.8,2.8-2.1,5.3-3.7,7.6c-1.6,2.3-3.5,4.2-5.8,5.8c-2.2,1.6-4.6,2.8-7.3,3.7s-5.4,1.3-8.3,1.3
				c-4,0-7.7-0.7-11-2c-3.3-1.3-6.1-3.1-8.5-5.5c-2.3-2.4-4.2-5.2-5.4-8.5c-1.3-3.3-1.9-7-1.9-11c0-4.1,0.6-7.8,1.9-11.2
				c1.3-3.4,3.1-6.2,5.4-8.6c2.3-2.4,5.2-4.2,8.5-5.6c3.3-1.3,7-2,11-2c3.9,0,7.4,0.7,10.6,2c3.2,1.3,5.8,3.3,8.1,5.8
				c2.2,2.5,3.9,5.5,5.1,9c1.2,3.5,1.8,7.4,1.8,11.7V58h-38.9c0.4,4,1.8,7.1,4.2,9.5c2.4,2.4,5.5,3.6,9.3,3.6c2.6,0,4.9-0.7,7-2
				c2.1-1.4,3.6-3.3,4.7-5.7H467z M454.8,50.7c-0.3-3.7-1.6-6.8-4-9.1c-2.3-2.4-5.2-3.6-8.7-3.6c-3.5,0-6.5,1.2-8.8,3.5
				c-2.4,2.3-3.8,5.4-4.3,9.2H454.8z"/>
		</g>
		<g>
			<polygon className="st4" points="541.3,33.5 541.3,10.1 517.9,10.1 517.9,33.5 494.5,33.5 494.5,56.9 508.2,56.9 529.6,35.5 
				551,56.9 564.7,56.9 564.7,33.5 			"/>
			<rect x="517.9" y="56.9" className="st4" width="23.4" height="23.4"/>
		</g>
	</g>
	<g className="st2">
		<rect x="-174.8" y="253.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -242.8696 -19.2867)" width="60.2" height="60.2"/>
	</g>
</g>
<g id="Layer_3">
	<g>
		<g>
			<path className="st5" d="M0,0h27.5c4.9,0,9.1,0.6,12.7,1.9s6.6,3,8.9,5.3s4.1,4.9,5.3,8c1.2,3.1,1.8,6.3,1.8,9.9
				c0,3.6-0.6,6.9-1.8,9.8c-1.2,3-3,5.5-5.4,7.7s-5.4,3.8-9,5c-3.6,1.2-7.8,1.8-12.6,1.8h-13v21H0V0z M26.9,37.8
				c5,0,8.7-1.1,11.2-3.4c2.4-2.2,3.7-5.4,3.7-9.4c0-4-1.2-7.1-3.6-9.4c-2.4-2.3-6.1-3.4-11.3-3.4H14.6v25.6H26.9z"/>
			<path className="st5" d="M62.7,0h14.3v70.3H62.7V0z"/>
			<path className="st5" d="M118,60.8c-1.5,3.1-3.5,5.8-6.1,7.9c-2.6,2.1-5.7,3.2-9.3,3.2c-2.5,0-4.8-0.5-6.8-1.4c-2-0.9-3.7-2.2-5-3.8
				c-1.4-1.6-2.4-3.5-3.1-5.8c-0.7-2.2-1-4.7-1-7.3V18.9H101V50c0,2.9,0.8,5.3,2.3,7c1.5,1.8,3.7,2.6,6.5,2.6c1.4,0,2.8-0.3,4-0.8
				c1.2-0.6,2.3-1.3,3.3-2.2c0.9-0.9,1.7-2,2.2-3.2c0.6-1.2,0.8-2.5,0.8-3.8V18.9h14.3v51.5h-13l-1.3-9.6H118z"/>
			<path className="st5" d="M175.8,33.5c-0.8-2.1-2.1-3.7-4.1-4.7c-2-1-4.1-1.5-6.3-1.5c-2,0-3.8,0.5-5.4,1.5c-1.7,1-2.5,2.4-2.5,4.3
				c0,1.6,0.7,2.8,2.1,3.6c1.4,0.7,3.2,1.4,5.6,1.9c2.1,0.5,4.4,1,7,1.6c2.6,0.5,5,1.3,7.3,2.4c2.3,1.1,4.2,2.6,5.8,4.5
				c1.6,2,2.3,4.6,2.3,8c0,2.8-0.6,5.2-1.8,7.3c-1.2,2.1-2.8,3.8-4.8,5.2c-2.1,1.4-4.5,2.5-7.3,3.2c-2.8,0.7-5.8,1.1-9,1.1
				c-7.2,0.1-12.8-1.5-17-4.6c-4.2-3.2-6.6-7.7-7.1-13.5h11.5c0.4,1.4,1,2.7,1.9,3.7c0.8,1,1.8,1.9,2.9,2.6c1.1,0.7,2.3,1.2,3.7,1.5
				c1.3,0.3,2.7,0.5,4,0.5c2.4,0,4.5-0.5,6.3-1.5c1.8-1,2.6-2.4,2.6-4.4c0-1.8-0.7-3.1-2.2-3.8c-1.5-0.7-3.5-1.4-6-2
				c-2-0.5-4.3-1-6.8-1.5s-4.9-1.3-7.1-2.3c-2.2-1-4.1-2.5-5.6-4.4c-1.5-1.9-2.3-4.5-2.3-7.7c0-2.7,0.6-5.1,1.7-7.2
				c1.1-2.1,2.7-3.9,4.7-5.3c2-1.5,4.3-2.6,7-3.3c2.7-0.7,5.6-1.1,8.6-1.1c5.7,0,10.5,1.4,14.4,4.1c3.9,2.8,6.2,6.8,7.1,12H175.8z"
				/>
			<path className="st5" d="M206.8,74.7c0.7,2,2.1,3.6,4.1,4.8c2,1.2,4.2,1.8,6.7,1.8c4,0,7-1.4,9.1-4.1c2.1-2.8,3.1-6.2,3.1-10.4
				l-0.1-6.5h-2.1c-0.8,3.5-2.6,6.3-5.1,8.5c-2.6,2.1-5.7,3.2-9.2,3.2c-3.1,0-5.9-0.6-8.4-1.9c-2.5-1.3-4.7-3.1-6.5-5.4
				c-1.8-2.3-3.2-5.1-4.2-8.4c-1-3.3-1.5-7-1.5-11c0-4.1,0.5-7.9,1.6-11.3c1-3.4,2.5-6.3,4.3-8.8c1.9-2.4,4.1-4.3,6.6-5.7
				c2.6-1.3,5.4-2,8.5-2c3.5,0,6.4,1,8.8,2.9c2.4,1.9,4.1,4.7,5.1,8.4h2.1l1.3-9.8h13v47.9c0.1,8.2-2.1,14.5-6.6,18.8
				c-4.5,4.3-11.2,6.5-20.1,6.5c-2.9,0-5.7-0.4-8.4-1.1c-2.6-0.7-5-1.9-7.1-3.3c-2.1-1.5-3.8-3.3-5.2-5.4c-1.4-2.1-2.4-4.6-2.9-7.4
				H206.8z M218.3,29.7c-3.5,0-6.2,1.4-8.3,4.1c-2.1,2.7-3.1,6.3-3.1,10.8c0,4.5,1,8.1,3.1,10.8c2.1,2.7,4.9,4.1,8.3,4.1
				c3.3,0,6-1.3,8.2-4s3.2-6.2,3.2-10.8c0-2.2-0.3-4.2-0.8-6c-0.6-1.9-1.3-3.5-2.3-4.8c-1-1.4-2.2-2.4-3.6-3.2S220,29.7,218.3,29.7z
				"/>
			<path className="st5" d="M287.9,32.3H281c-1.7,0-3.3,0.3-4.9,1c-1.6,0.7-3,1.6-4.2,2.7s-2.2,2.6-2.9,4.3c-0.7,1.7-1.1,3.6-1.1,5.8
				v24.2h-14.2V18.9h12.9l1.3,11h2.1c1.2-3.7,2.8-6.5,4.8-8.3c2-1.8,4.5-2.7,7.6-2.7h5.6V32.3z"/>
			<path className="st5" d="M325.5,37.4c0-3.3-0.9-5.7-2.6-7.2c-1.8-1.5-4.1-2.2-7.1-2.2c-2.7,0-4.9,0.7-6.8,2.1c-1.9,1.4-3,3.3-3.6,5.8
				H293c0.5-3.1,1.5-5.8,3-8.2c1.5-2.3,3.3-4.2,5.4-5.7s4.5-2.6,7.2-3.4c2.6-0.7,5.4-1.1,8.3-1.1c3.1,0,6.1,0.4,8.8,1.2
				c2.7,0.8,5.1,2,7.2,3.6c2.1,1.6,3.7,3.7,4.8,6.2c1.2,2.5,1.8,5.5,1.8,8.9v33h-13l-0.9-7.9h-2c-2,3.1-4.4,5.5-7.1,7
				c-2.7,1.6-6,2.3-9.9,2.3c-4.4,0-7.9-1.2-10.7-3.7c-2.8-2.5-4.2-6-4.2-10.5c0-3.5,0.8-6.3,2.4-8.7c1.6-2.3,3.7-4.2,6.3-5.7
				s5.6-2.5,8.9-3.1c3.4-0.6,6.8-0.9,10.2-0.9h6V37.4z M325.5,46.8h-5.7c-2,0-3.8,0.2-5.5,0.5c-1.7,0.3-3.2,0.8-4.5,1.5
				c-1.3,0.7-2.3,1.5-3.1,2.5c-0.7,1-1.1,2.2-1.1,3.6c0,2.1,0.7,3.7,2.2,4.8c1.5,1.1,3.3,1.7,5.4,1.7c1.8,0,3.4-0.3,4.9-0.8
				c1.5-0.5,2.8-1.3,3.9-2.3c1.1-1,2-2.1,2.6-3.4c0.6-1.3,0.9-2.7,0.9-4.2V46.8z"/>
			<path className="st5" d="M397.9,70.3h-13l-1.3-10.2h-2.1c-1.2,3.6-3,6.4-5.4,8.5c-2.4,2.1-5.5,3.1-9.2,3.1c-3.1,0-5.8-0.7-8.4-2
				c-2.5-1.3-4.6-3.2-6.4-5.6c-1.8-2.4-3.1-5.3-4.1-8.7c-1-3.4-1.5-7.3-1.5-11.6c0-4,0.5-7.7,1.5-11c1-3.3,2.3-6.1,4.1-8.4
				c1.8-2.3,3.9-4.1,6.4-5.3c2.5-1.2,5.3-1.9,8.4-1.9c3.6,0,6.6,1,9,2.9c2.4,1.9,4.3,4.6,5.6,8.2h2.1V0h14.3V70.3z M372.3,29.7
				c-3.5,0-6.2,1.4-8.3,4.1c-2.1,2.7-3.1,6.3-3.1,10.8c0,4.5,1,8.1,3.1,10.8c2.1,2.7,4.9,4.1,8.3,4.1c3.4,0,6.1-1.3,8.2-4
				c2.1-2.6,3.1-6.2,3.1-10.8c0-2.2-0.3-4.2-0.8-6.1c-0.6-1.8-1.3-3.4-2.3-4.7c-1-1.3-2.2-2.4-3.6-3.1
				C375.5,30.1,374,29.7,372.3,29.7z"/>
			<path className="st5" d="M457,53.3c-0.8,2.8-2.1,5.3-3.7,7.6c-1.6,2.3-3.5,4.2-5.8,5.8c-2.2,1.6-4.6,2.8-7.3,3.7s-5.4,1.3-8.3,1.3
				c-4,0-7.7-0.7-11-2c-3.3-1.3-6.1-3.1-8.5-5.5c-2.3-2.4-4.2-5.2-5.4-8.5c-1.3-3.3-1.9-7-1.9-11c0-4.1,0.6-7.8,1.9-11.2
				c1.3-3.4,3.1-6.2,5.4-8.6c2.3-2.4,5.2-4.2,8.5-5.6c3.3-1.3,7-2,11-2c3.9,0,7.4,0.7,10.6,2c3.2,1.3,5.8,3.3,8.1,5.8
				c2.2,2.5,3.9,5.5,5.1,9c1.2,3.5,1.8,7.4,1.8,11.7V48h-38.9c0.4,4,1.8,7.1,4.2,9.5c2.4,2.4,5.5,3.6,9.3,3.6c2.6,0,4.9-0.7,7-2
				c2.1-1.4,3.6-3.3,4.7-5.7H457z M444.8,40.7c-0.3-3.7-1.6-6.8-4-9.1c-2.3-2.4-5.2-3.6-8.7-3.6c-3.5,0-6.5,1.2-8.8,3.5
				c-2.4,2.3-3.8,5.4-4.3,9.2H444.8z"/>
		</g>
		<g>
			<polygon className="st6" points="531.3,23.5 531.3,0.1 507.9,0.1 507.9,23.5 484.5,23.5 484.5,46.9 498.2,46.9 519.6,25.5 541,46.9 
				554.7,46.9 554.7,23.5 			"/>
			<rect x="507.9" y="46.9" className="st7" width="23.4" height="23.4"/>
		</g>
	</g>
</g>
</svg>
    )
}

export default PGLogo