import styled, { css } from "styled-components";
import React from "react";
import { mq, brand, font } from "../../../styles";

const Req = () => (
  <span style={{ color: "red", display: "inline-block" }}>*</span>
);
const FormElement = styled.div`
  ${font.imports.sherika}

  margin-bottom: 10px;
  background-color: ${brand.colors.pg_primary_dark};

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #fff;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
  }

  small {
    display: block;
    background: #f8d7da;
    border: 1px solid #f5c6cb;
    position: relative;
    color: #721c24;
    z-index: 500;
    padding: 3px 10px;
    margin-top: 0.5rem;
  }

  small:empty {
    display: none;
  }

  select {
    width: 100%;
    background-color: ${brand.colors.pg_primary_dark};
    color: #fff;
    border-color: ${brand.colors.pg_blue};
  }

  input,
  textarea {
    width: 100%;
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid ${brand.colors.pg_blue};
    background-color: ${brand.colors.pg_primary_dark};
    color: #fff;
  }

  textarea {
    padding: 5px 10px;
    background-color: ${brand.colors.pg_primary_dark};
    color: #fff;
  }

  ${props =>
    props.onWhite &&
    css`
      background-color: #fff;

      label {
        color: ${brand.colors.pg_primary_dark};
      }

      select {
        width: 100%;
        background-color: ${brand.colors.pg_primary_dark};
        color: #fff;
        border-color: ${brand.colors.pg_blue};
      }

      input,
      textarea {
        width: 100%;
        font-size: 16px;
        padding: 5px 10px;
        border: 1px solid ${brand.colors.pg_blue};
        background-color: #fff;
        color: ${brand.colors.pg_primary_dark};
      }

      textarea {
        padding: 5px 10px;
        background-color: #fff;
        color: ${brand.colors.pg_primary_dark};
      }
    `};
`;

const SubmitButton = styled.button`
  display: inline-block;
  padding: 0 25px;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: center;
  font-family: ${font.family.sherika};
  font-weight: 700;
  font-size: 16px;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  border-radius: 25px;
  border-width: 5px;
  border-color: transparent;
  background: ${brand.colors.pg_blue};
  color: #fff;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .button-text {
    margin-right: 10px;
  }
`;

const Container = styled.div`
  ${font.imports.sherika}

  margin-bottom: 15px;
  font-size: 12px;
  color: ${brand.colors.pg_primary_dark};
  text-align: justify;
  text-align: 20px;
  line-height: 18px;
`;

const StyledLink = styled.a`
  color: ${brand.colors.pg_blue};
`;

export { SubmitButton, FormElement, Container, StyledLink, Req };
