import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faCoffee, faPlane } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { brand, mq, font } from "../../../styles";
import IconsList from "../../blocks/icons/Icons";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";




const MenuContainer = styled.ul`
${font.imports.sherika};

  display: none;
  width: 100%;
  padding: 0;
  margin-bottom:0;
  font-weight: 400;
  position: absolute;
  z-index: 600;
  color: ${brand.colors.pg_primary_light};
  list-style: none;
  @media screen and ${mq.minMd} {
   
    background: transparent;
    min-height: auto;
    border: 0;
    position: relative;
    display: block;
    top: 0;
    box-shadow: none;
    margin-left: 0;
  }
  @media screen and (max-width: 991px){
    width: 85%;
  }

  .lang-title {
    display: none;
    margin: 5px;
    font-family: ${font.family.sherika};
    font-weight: 800;
    font-size: 14px;
    padding: 10px 20px;
    pointer-events: none;
    color: #fff;
    text-decoration: underline;
    @media only screen and ${mq.maxMd} {
      display: block;
    }
  }

  .lang-option {
    display: none;
    margin: 5px;
    font-family: ${font.family.sherika};
    font-weight: 800;
    font-size: 14px;
    padding: 10px 20px;
    pointer-events: none;
    color: ${props => props.langActive ? brand.colors.pg_orange : '#fff'};

    @media only screen and ${mq.maxMd} {
      display: block;
    }

    a {
      text-decoration: none;
      pointer-events: all;
      color: ${props => props.langActive ? brand.colors.pg_orange : '#fff'};

      &:active {
        color: ${brand.colors.pg_orange};
      }
    }
  }
  > li {
    display: block;
    max-width: 80%;
    margin: 10px 15px;
    font-size: 14px;
    font-weight: 800;
    & :last-of-type{  
      > .top-level{
        background-color: ${brand.colors.pg_blue};
        border-radius: 26px;
        padding-left: 10px;
        padding-right: 10px;
        &:hover, :focus-within{
          background-color: #fff;
        }
        @media screen and (max-width: 991px) {
          padding: 5px 1rem;
          margin-left: 20px;
        }
        > a{
       padding: 5px;
        text-align: center;
        @media screen and (max-width: 991px) {
          padding: 5px 10px;
          
        }
      }
      }
      @media screen and (max-width: 991px) {
        width: 220px;
        margin: 10px 0px;
      }
    }
    @media screen and ${mq.minMd} {
      display: inline-block;
      max-width: 100%;
    }
    &:not(:nth-last-child(2)):hover, :focus-within {
       &.Products-menu > .top-level > .menu-link , &.Careers-menu > .top-level > .menu-link{
        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: none;
        color: #226CF7;
        @media screen and (max-width: 991px) {
          border: none;
          box-shadow: none;
        }
      }
      .sub-menu {
        transform: translateX(0);
        &:after {
          opacity: 1;
        }
        @media screen and (max-width: 991px){
          display: block;
          position:relative;
         }
      }
    }
    
    &:first-child div {
      // padding-left: 1rem;
      // @media screen and (min-width: 1030px) {
      //   padding-left: 2rem;
      // }
    }
    
    &.mobile-only {
      display: block;
      @media screen and ${mq.minMd} {
        display: none;
      }
    }

    .sub-menu.2xHeight {
      max-height: 205px;
    }
    .3xHeight {
      max-height: 307px;
    }
    .4xHeight {
      max-height: 409px;
    }
    .5xHeight {
      max-height: 511px;
    }
    .6xHeight {
      max-height: 613px;
    }
  }
  @media screen and (max-width: 991px) {
    right: 0px;
    margin: 0%;
    li{
      margin: 0px;
      max-width: 100%;
      .top-level{
        padding: 15px 1rem;
        .menu-link{
          padding: 0px;
          border: none;
          transition: all 0.5s ease 0s;
        }
      }
    }
    ${props => props.open && css`
      display: inline-block;
      width: 250px;
      min-height: auto;
      background: ${brand.colors.pg_primary_dark};
      top: 44px;
      padding-bottom: 10px;
    `}
  }
  .Careers-menu{
    > .sub-menu {
    max-height: 155px;
    &:before{
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 63.5%;
    }
    @media screen and (max-width: 991px){
      
      &::before{
        content: "";
        border: none;
      }
    }
  }
  }
  
  
  .top-level {
    display: block;
    // min-width : 992px
    @media screen and ${mq.minMd} {
      
      display: inline-block;
      border: 0;
      max-width: 100%;
    } 
    // @media screen and (min-width: 1030px) {
    //   padding: 35px 10px;
    // }
    
    ${props => props.affixed && css`
      // color: #fff;
      
    `}
    &:hover, :focus-within{
      + .sub-menu {
         transform: translateY(00);
         @media screen and (max-width: 991px){
          display: block;
          position:relative !important;
         }
      }
    }
  
  }
  .sub-menu {
    overflow: hidden;
    margin: 0;
    height: auto;
    
    width: 250px;
    bottom: 0;
    max-height: 255px !important;
    position: fixed;
    transform: translateY(-180%);
    background-color: ${brand.colors.pg_primary_dark};
    list-style: none;
    border-radius: 5px;
    padding: 20px 0px 25px;
    &:before{
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 59.5%;
      border: 0.01px outset rgba(0, 0, 0, 0.03);
    }
    &::-webkit-scrollbar {
      width: 0px;
    }
    @media screen and (max-width: 991px){
      width: 100%;
      display: none;
      right: 0px;
      padding: 10px 0px;
      max-height: 180px;
      border-left: none;
      border-right: none;
      border-radius: 0px;
      box-shadow: none;
      transform: none;
      list-style: none;
      max-width: 100%;
      background-color: #1B64EF;
      &::before{
        position: relative;
        content: "";
        border: none;
      }
      &&::after{
        
      }
     }
    li{
      padding: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      svg{
        color: #B9CEDD;
        font-size: 14px;
        margin-bottom: 8px;
      }
      @media screen and (max-width: 991px){
        background-color : #1B64EF;
        padding: 10px 22px;
        margin: 0px;
        &::after {
          content: '';
        }
        a.menu-link{
          width: 100%;
        }
      }
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      transition: opacity .5s ease;
      right: 0;
      background: linear-gradient(to right, #0005, #0008 );
      top: 0;
      bottom: 0;
      overflow: hidden;
      transform: translate(100%);
      pointer-events: none;
      width: 100vw;
      opacity: 0;

      @media only screen and (max-width: 1150px) {
        top: 80px;
      }
      
      @media screen and ${mq.minMd} {
        top: 43px;
      }
    }
    
    @media screen and ${mq.minMd} {
      top: 45px;
      // box-shadow: none;
    }
    
    .menu-link {
      padding-left: 1rem;

    }
      
    span.menu-link {
      line-height: 1.2;
      color: #757575;
      margin-top: 2rem;
      margin-right: 4rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    
    .heading:not(:first-child) {
      &:before {
        content: '';
        margin-top: 2rem;
        display: block;
        height: 2px;
        background: #eaeaea;
        margin-left: 1rem;
        margin-right: 2rem;
      }
    }
    
    a.menu-link {
      font-size: 14px;
      line-height: 1.3;
      color: inherit;
      width: 70%;
      
      @media screen and ${mq.minMd} {
        font-size: 14px;
      }
      
      &:hover, :focus-within {
        color: #226CF7;
      }
    }
  }

  .menu-link {
    display: block;
    
    &:hover, :focus-within {
     text-decoration: none;
     color: #226CF7;
    }
    
    @media screen and ${mq.minMd} {
      top: 100px;
    } 
  }

  .lang-option {
    display: none;
    margin: 5px;
    font-family: ${font.family.sherika};
    font-weight: 800;
    font-size: 14px;
    padding: 10px 20px;
    pointer-events: none;

    @media only screen and ${mq.maxMd} {
      display: block;
    }

    a {
      text-decoration: none;
      pointer-events: all;

      &:active {
        color: ${brand.colors.pg_orange};
      }
    }
  }
`;



const Menu = ({data, affixed, open, enTo, frTo}) => {

  const compQuery = useStaticQuery(graphql`
        query {
            cruise: file(base: { eq: "icon-Cruise.png" }) {
                childImageSharp {
                    fixed(width: 20, height: 20, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            air: file(base: { eq: "icon-Air.png" }) {
              childImageSharp {
                  fixed(width: 20, height: 20, webpQuality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
          rail: file(base: { eq: "icon-Rail.png" }) {
            childImageSharp {
                fixed(width: 20, height: 20, webpQuality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        hospitality: file(base: { eq: "icon-Hospitality.png" }) {
          childImageSharp {
              fixed(width: 20, height: 22, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
        }
        loyalty: file(base: { eq: "icon-Loyalty-Solutions.png" }) {
          childImageSharp {
              fixed(width: 20, height: 20, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
        }
    }
    `);

  return(
  <nav>
    <MenuContainer open={open} affixed={affixed} enTo frTo id="menu">
      { data.map((item, index) => (
        <li key={index} className={(item.class ? item.class : '' )}>
          <div className={'top-level '}><MenuLink item={item} /></div>
          { item.submenu ? generateSubMenu(item.submenu, compQuery) : '' }
        </li>
      ))}
      <span className="lang-title">{typeof window !='undefined' ? window.location.href.indexOf('/fr') > -1 ? 'Langue' : 'Language' : null}</span>
      <span className="lang-select-clickable lang-option en"><Link to={enTo} style ={{color: typeof window !='undefined' ? window.location.href.indexOf('/fr') > -1 ? '#fff' : '#F74E01' : null}}>English (en-CA)</Link></span>
      <span className="lang-select-clickable lang-option fr" ><Link to={frTo} style ={{color: typeof window !='undefined' ? window.location.href.indexOf('/fr') > -1 ? '#F74E01' : '#fff' : null}}>Français (fr-CA)</Link></span>
    </MenuContainer>
  </nav>
  )
  };

export default Menu;

const generateSubMenu = (items, query) => {
  let subMenuSize = items.length;
  let subMenuHeight = subMenuSize * 53;
  let enIconSource = '../../assets/icon-';
  let frIconSource = '../../../assets/icon-';
  
  console.log(query);
  console.log(items);

  return(
  <ul className={"sub-menu"} style={{maxHeight: subMenuHeight}}>
    { items.map((item, index) => (
      
      <li key={index} className={item.url ? item.title : 'heading' }> <GatsbyImage fixed={query[item.labelIcon].childImageSharp.fixed} imgStyle={{objectFit: "contain"}}style={{width: '20px', height: '25px', marginLeft: '10px'}}/> <MenuLink item={item} /> {(item.icon === "down" ? <FontAwesomeIcon icon={faChevronDown} /> : item.icon === "external" ? IconsList.external : '')}</li>
    )) }
  </ul>
)};

const MenuLink = ({item}) => {
  if(item.url) {
    if(!item.target) {
      return <Link className={'menu-link'} to={item.url}>{ item.title }</Link>
    } else {
      return <a className={'menu-link external'} data-temp={item.target} target={item.target} rel={'noreferrer'} href={item.url}>{ item.title }</a>
    }

  } else {
    return <span className={'menu-link' + item.title }>{ item.title.split('\n').map((str, key) => <div key={key}>{ str } <FontAwesomeIcon style={{marginLeft: '10px'}} icon={faChevronDown} /></div>) } </span>
  }
}
