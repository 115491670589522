module.exports = [
  {
    title: 'Produits',
    url: '/fr/produits/'
  },
  {
    title: 'Industries',
    submenu: [
      {
        title: 'Transport aérien',
        url: '/fr/industries/transport-aerien/',
        labelIcon: 'air'
      },
      {
        title: 'Hôtellerie',
        url: '/fr/industries/hotellerie/',
        labelIcon: 'hospitality',
      },
      {
        title: 'Croisière',
        url: '/fr/industries/croisiere/',
        labelIcon: 'cruise'
      },
      {
        title: 'Rail',
        url: '/fr/industries/rail/',
        labelIcon: 'rail'
      },
      { 
        title: 'Solutions de fidélisation',
        url: 'https://www.points.com/',
        icon: "external",
        labelIcon: 'loyalty',
        target: "_blank"
      },
    ]
  },
  {
    title: 'Partenaires',
    url: '/fr/partenaires/'
  },
  {
    title: 'À propos de',
    url: '/fr/a-propos-de/'
  },
  {
    title: 'Carrieres',
    url: '/fr/carrieres/'
  },
  {
    title: 'Demandez une démonstration',
    url: '/fr/contact/demandez-une-demonstration/'
  }
];
