import GatsbyImage from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../button/Button";

const StayExtendCon = styled.div`

${font.imports.sherika}

    width: 95%;
    max-width: 1400px;
    margin: 60px auto;
    height: auto;
    min-height: 410px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    @media only screen and ${mq.maxMd} {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .left-section {
        /*padding-top: 88px;*/
        flex-basis: 45%;
        max-width: 500px;
        text-align: center;
        @media only screen and ${mq.maxMd} {
            flex-basis: 260px;
            width: 95%;
            max-width: 500px;
        }

        .gatsby-image-wrapper {
            transform: translateY(40px);
        }
    }

    .right-section {
        display:flex;
        flex-direction:column;
        justify-content:flex-end;

        @media only screen and ${mq.maxMd} {
            justify-content: center;
            padding-bottom: 40px;
        }

        .icon-container {
            @media only screen and ${mq.maxMd} {
                margin: auto;
            }
        }

        .copy-con {

            @media only screen and ${mq.maxMd} {
                text-align: center;
            }
            font-family: ${font.family.sherika};
            h2 {
                color: ${brand.colors.pg_primary_dark};
                font-weight: 700;
                font-size: 36px;
                @media only screen and ${mq.maxMd} {
                    text-align: center;
                }
            }
            p {
                color: ${brand.colors.pg_secondary_dark};
                font-weight: 400;
                font-size: 20px;
                line-height: 22px;
                margin-bottom: 30px;
                max-width: 450px;
                @media only screen and ${mq.maxMd} {
                    text-align: center;
                }
            }

            ul {
                list-style-type: none;
                color: ${brand.colors.pg_secondary_dark};
                font-weight: 400;
                font-size: 20px;
                margin-left: 10px;
                max-width: 475px;
                margin-bottom: 60px;
                li {
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    justify-content: flex-start;
                    margin-bottom: 10px;

                    .bullet-icon-con {
                        flex-basis: 15px;
                        margin-right: 10px;
                    }
                    .bullet-copy {
                        line-height: 20px;
                    }
                }
            }

            /*
            .button-con > span {
              width: 200px;
              @media only screen and ${mq.maxMd} {
                  width: ${props => (props.fr === true ? "220px" : "200px")};
              }
            }*/
            span {
                margin: auto;
    
                @media only screen and (max-width: 500px) {
                    width: 225px;
                }
            }

            /*span {
                margin: auto;

                @media only screen and (max-width: 500px) {
                    width: 225px;
                }
            }
            */
        }
    }
`;

const StayExtend = ({
  title,
  copy,
  buttonLabel,
  buttonDestination,
  image,
  graphicStyle,
  fr,
}) => {
  const compQuery = useStaticQuery(graphql`
    query {
      productGraphic: file(base: { eq: "Product-Speed-Pass.png" }) {
        childImageSharp {
          fixed(width: 200, height: 400, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      stayExtendIcon: file(
        base: { eq: "Icons_Product_hospitality_final_StayExtend.png" }
      ) {
        childImageSharp {
          fixed(width: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      rightArrowIcon: file(base: { eq: "arrow-right.png" }) {
        ...ButtonIcon
      }
    }
  `);

  return (
    <StayExtendCon fr>
      <div className="left-section">
        <GatsbyImage
          fixed={
            image
              ? image.childImageSharp.fixed
              : compQuery["productGraphic"].childImageSharp.fixed
          }
          imgStyle={graphicStyle}
        />
      </div>

      <div className="right-section">
        <div className="icon-container">
          <GatsbyImage
            fixed={compQuery["stayExtendIcon"].childImageSharp.fixed}
          />
        </div>
        <div className="copy-con">
          <h2>{title ? title : "StayExtend"}</h2>
          {copy ? (
            <p dangerouslySetInnerHTML={{ __html: copy }}></p>
          ) : (
            <p>
              Offer Economy passengers more comfort and space by letting them
              block the seat—or even an entire row—next to them. With{" "}
              <strong>Seat Blocker</strong>, seats are “soft blocked,”
              generating revenue on unsold inventory and dynamically adapting
              seat allocation based on load factor and seat inventory.
            </p>
          )}

          <div className="button-con">
            <Button
              destination={
                buttonDestination
                  ? buttonDestination
                  : "/industries/hospitality/demo/"
              }
              label={buttonLabel ? buttonLabel : "Request a demo"}
              orange
              whiteText
              pill
            />
          </div>
        </div>
      </div>
    </StayExtendCon>
  );
};

export default StayExtend;
