import React from "react";
import styled, { css } from "styled-components"
import { brand, mq } from '../../../styles';

const DividerContainer = styled.div`
  width: 100%;
  height: 100px;
  margin: auto;
  display: flex;
  align-items:center;
  justify-content: center;
  position: relative;

  div {
    height: 109px;
  }

  .left-portion {
    background-color: ${brand.colors.pg_orange};
    flex-grow: 1;
    &:after {
        content: '';
        display: block;
        width: 0px;
        height: 0px;
        position: relative;
        left: 100%;
        border-style: solid;
        border-width: 53px 0 52px 52px;
        border-color: #fff transparent ${brand.colors.pg_primary_dark} ${brand.colors.pg_orange};
        transform: rotate(0deg);
    }
  }

  .center-portion {
    flex-basis: 103px;
    background-color: ${brand.colors.pg_primary_dark}
  }

  .right-portion {
    background-color: ${brand.colors.pg_blue};
    flex-grow: 1;

    &:before {
        content: '';
        display: block;
        width: 0px;
        height: 0px;
        position: relative;
        left: -51px;
        border-style: solid;
        border-width: 53px 52px 52px 0;
        border-color: #fff ${brand.colors.pg_blue} ${brand.colors.pg_primary_dark} transparent;
        transform: rotate(0deg);
    }
  }

  ${props => props.inverse && css`
    .left-portion:after {
          border-color: ${brand.colors.pg_primary_dark} transparent #fff ${brand.colors.pg_orange};
      }

    .right-portion:before {
          border-color: ${brand.colors.pg_primary_dark} ${brand.colors.pg_blue} #fff transparent;
      }
  `}
`;

const BlueOrangeDivider = ({...props}) => {

    return (
      <>
        <DividerContainer {...props}>
            <div className={"left-portion"}></div>
            <div className={"center-portion"}></div>
            <div className={"right-portion"}></div>
        </DividerContainer>
      </>
    );
  };

export default BlueOrangeDivider;

