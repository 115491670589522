import GatsbyImage from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../button/Button";
import IconsList from "../icons/Icons";

const LoyaltyCon = styled.div`

${ font.imports.sherika }

    width: 95%;
    max-width: 1200px;
    margin: 60px auto;
    height: auto;
    min-height: 530px;
    display: flex;
    align-items: flex-start;
    justify-content: ${(props) => props.spaceBetween ? "space-between" : "center"};

    @media only screen and ${mq.maxMd} {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    div {
        flex-basis: 45%;

        @media only screen and ${mq.maxMd} {
            width: 95%;
            text-align: center;
        }
    }

    .left-section {
        padding-top: 88px;
        width: 95%;
        max-width: 500px;

        @media only screen and ${mq.maxMd} {
            padding-top: 20px;
        }

        .gatsby-image-wrapper {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .right-section {
        @media only screen and ${mq.maxMd} {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .copy-con {
            font-family: ${font.family.sherika};
            @media only screen and ${mq.maxMd} {
                text-align: center;
            }
            h2 {
                color: ${brand.colors.pg_primary_dark};
                font-weight: 700;
                font-size: 36px;
            }
            p {
                color: ${brand.colors.pg_secondary_dark};
                font-weight: 400;
                font-size: 20px;
                line-height: 22px;
                margin-bottom: 30px;
                max-width: 450px;
                @media only screen and ${mq.maxMd} {
                    margin: 0 auto 30px;
                }
            }

            ul {
                list-style-type: none;
                color: ${brand.colors.pg_secondary_dark};
                font-weight: 400;
                font-size: 20px;
                margin-left: 10px;
                max-width: 475px;
                margin-bottom: 60px;
                @media only screen and ${mq.maxMd} {
                    text-align: center;
                    margin: 20px auto;
                }
                li {
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    justify-content: flex-start;
                    margin-bottom: 10px;
                    @media only screen and ${mq.maxMd} {
                        justify-content: center;
                        text-align: left;
                    }

                    .bullet-icon-con {
                        flex-basis: 15px;
                        margin-right: 10px;
                    }
                    .bullet-copy {
                        line-height: 20px;
                    }
                }
            }

            span {
                @media only screen and ${mq.maxMd} {
                    width: 300px;
                }
                @edia only screen and (max-width: 380px) {
                    width: 95%;
                }
            }
        }
    }
`;

const PointsLoyalty = ({headline, copy, fr, ...props}) =>{

    const compQuery = useStaticQuery(graphql`
        query {
            productGraphic: file(base: { eq: "PlatformPartnershipDevice.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500, webpQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            loyaltyIcon: file(base: {eq: "loyalty-circles.png"}){
                childImageSharp {
                    fixed(width: 50, height: 50, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            circleCheckmark: file(base: {eq: "circle-checkmark.png"}){
                childImageSharp {
                    fixed(width: 16, height: 15, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
                ...ButtonIcon
              }
        }
    `);

    const bulletIcon = <GatsbyImage fixed={compQuery['circleCheckmark'].childImageSharp.fixed} />;

    return (
        <LoyaltyCon {...props}>
            <div className="left-section">
                <GatsbyImage fluid={compQuery['productGraphic'].childImageSharp.fluid} />
            </div>

            <div className="right-section">
                <div classname="icon-container">
                    <GatsbyImage fixed={compQuery['loyaltyIcon'].childImageSharp.fixed} />
                </div>
                <div className="copy-con">
                    <h2>
                        {headline? headline : 'Loyalty by Points'}
                    </h2>

                    {
                        copy ? <p dangerouslySetInnerHTML={{__html: copy}}></p> :
                    <>
                        <p>The <strong>Loyalty Commerce Platform</strong> by Points, a Plusgrade company, gives members the ability to exchange, earn, and redeem points/miles on ancillaries and through third-party or co-branded channels. By connecting your program to a network of industry-leading merchant partners you can grow ancillary revenue and expand the utility of your points/miles.</p>
                        <p>
                            Through <strong>Points</strong> you can allow members to:
                        </p>
                        <ul>
                            <li>
                                <span className="bullet-icon-con">{bulletIcon}</span> <span className="bullet-copy">Bid and pay for ancillaries with loyalty currency</span>
                            </li>
                            <li>
                                <span className="bullet-icon-con">{bulletIcon}</span> <span className="bullet-copy">Buy, gift and transfer points</span>
                            </li>
                            <li>
                                <span className="bullet-icon-con">{bulletIcon}</span> <span className="bullet-copy">Exchange, earn, redeem through white-label third-party or co-branded channels</span>
                            </li>
                        </ul>
                    </>
                    }

                    <Button destination ={'https://www.points.com'} label={fr ? 'Visitez Points pour en savoir plus' : 'Visit Points to learn more'} rel={'noreferer'} target={'_blank'} faIcon={IconsList.external} orange whiteText pill external/>
                </div>
            </div>
        </LoyaltyCon>
    )
}

export default PointsLoyalty;