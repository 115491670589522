import GatsbyImage from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../button/Button";
import IconsList from "../icons/Icons";



const LoyaltyCommerce = ({fr}) =>{

    const compQuery = useStaticQuery(graphql`
        query {
            productGraphic: file(base: { eq: "LaptopDevice.jpg" }) {
                childImageSharp {
                    fixed(width: 400, height: 225, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            seatBlockerIcon: file(base: {eq: "seatBlockerIcon.png"}){
                childImageSharp {
                    fixed(width: 50, height: 50, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
                ...ButtonIcon
              }
              lcrProductImage: file(base: { eq: "LCR-Device-SWA.png" }) {
                childImageSharp {
                    fixed(width: 260, height: 495, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
              }
              loyaltyIcon: file(base: {eq: "handPlus.png"}){
                childImageSharp {
                    fixed(width: 60, height: 60, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
              }
        }
    `);

    const ProductsContainerLCR = styled.div`
    
    ${ font.imports.sherika }
    width: 95%;
      max-width: 1200px;
      margin: auto;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: center;
      padding-top: 80px;

      @media only screen and ${mq.maxMd} {
        flex-direction: column-reverse;
        align-items: center;
      }
  
      .left-con, .right-con {
        flex-basis: 46%;
      }
  
      .left-con {
        display: flex;
        justify-content: center;
      }
  
      .right-con {
        @media only screen and ${mq.maxMd} {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        }
        .copy {
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
          h2 {
            font-family: ${font.family.sherika};
            font-weight: 600;
            font-size: 36px;
            color: ${brand.colors.pg_primary_dark};
            white-space: pre-wrap;
          }
  
          p {
            font-family: ${font.family.sherika};
            font-weight: 400;
            font-size: 20px;
            color: ${brand.colors.pg_secondary_dark};
            line-height: 22px;
            max-width: 450px;
            margin-bottom: 30px;
          }
        }
        span {
          @media only screen and ${mq.maxMd} {
            width: 300px;
          }
        }
      }
    `;

    return (
    <ProductsContainerLCR>
          <div className="left-con">
            <GatsbyImage fixed={compQuery['lcrProductImage'].childImageSharp.fixed} imgStyle={{objectPosition: "center bottom"}} />
          </div>
          <div className="right-con">
            <div className="icon-con">
              <GatsbyImage fixed={compQuery['loyaltyIcon'].childImageSharp.fixed} />
            </div>
            <div className="copy">
              <h2>{fr ? 'Commercialisation de devise de fidélisation' : 'Loyalty Currency\nRetailing'}</h2>
              <p dangerouslySetInnerHTML={{__html: fr ? 
                'Permettez aux membres des programmes de fidélisation d\'en faire plus avec leurs points et leurs miles, en augmentant la satisfaction des clients et en générant des revenus à forte marge. Points, une société de Plusgrade, transforme les programmes de fidélisation singuliers axés sur les gains en moteurs de revenus multiproduits.' 
                  : 
                'Enable loyalty program members to do more with their points and miles, while driving customer satisfaction and high-margin revenue. <strong>Loyalty Currency Retailing</strong> by Points, a Plusgrade company, turns singular earn-focused loyalty rewards programs into multi-product revenue drivers.'}}></p>
            </div>

            <Button destination={'https://www.points.com'} label={ fr ? 'Visiter Points pour en savoir plus' : 'Visit Points to learn more'} faIcon={IconsList.external} rel={'noreferer'} target={'_blank'} orange whiteText pill />
          </div>
        </ProductsContainerLCR>
    )
}

export default LoyaltyCommerce;