import React from "react";

import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { brand, mq } from "../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql } from "gatsby";

const ButtonElement = styled.span`
  display: inline-block;

  &:after {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    position: relative;
    left: 100%;
    top: -46px;
    border-style: solid;
    border-width: 23px 0 23px 20px;
    border-color: transparent transparent transparent ${props => props.orange? brand.colors.pg_orange: props.blue ? brand.colors.pg_blue : props.black ? '#000' : '#fff'};
    transform: rotate(0deg);
}
  a {
    display: inline-block;
    padding: 10px 30px;
    text-decoration: none;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 28px;
    transition: all .7s ease;
    border-width: 5px;
    border-color: transparent;
    width: 100%;
    // height: 42px;
    color: ${props => props.whiteText ? "#fff" : props.orangeText ? brand.colors.pg_orange : props.blueText ? brand.colors.pg_blue : "#000"};
    background-color: ${props => props.orange? brand.colors.pg_orange: props.blue ? brand.colors.pg_blue : props.black? '#000' : '#fff'};
    
    .button-icon-con {
      position:relative;
      left: 15px;
    }
    
    &:hover {
      text-decoration: none;
    }
    
  }

  ${props => props.pill && css`
    &:after {
      display: none;
    }

    a {
      border-radius: 26px;
    }
  `}

  @media only screen and ${mq.maxSm} {
    width: 200px;
    ${props => props.aboutButton && css`
    width: 300px;
  `}
  }
`;

const Button = ({ type, label, destination, children, target, rel, icon, faIcon, linkStyle, ...props }) => {

  const isExternalLink = (destination.indexOf('http') !== -1 );

  const buttonIcon = (icon ? <span className={"button-icon-con"}><GatsbyImage fixed={icon.childImageSharp.fixed} /></span> : faIcon ? faIcon : <></>);
  const internalLink = <Link target={target} rel={rel} data-type={'gatsby-link'} to={destination}>{label}{buttonIcon}</Link>;
  const externalLink = <a target={target} rel={rel} href={destination}>{label}{buttonIcon}</a>;

  return (
    <ButtonElement type={type} {...props}>
      { isExternalLink ? externalLink : internalLink }
    </ButtonElement>
  )
};

Button.propTypes = {
  label: PropTypes.any.isRequired,
  destination: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["white", "default", "accent", "outline"]),
};

Button.defaultProps = {
  label: 'Click here',
  destination: '#',
  type: "default",
};

export default Button;

export const query = graphql`
  fragment ButtonIcon on File {
    childImageSharp {
      fixed(width: 16, height: 16, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  `;