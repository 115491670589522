import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { mq } from "../../../styles"
import PartnerCard from "./PartnerCard";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const PartnerGridElement = styled.div`
    text-align: center;
    margin: 0 auto 20px;
    .all-partners{
      padding-top: 20px;

      .partners {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 45px;

        @media only screen and (max-width: 884px) {
          justify-content: center;
        }
      }
      @media screen and ${mq.minSm} {
        padding-top: 75px;
      }
    }
    a.all-partners-link {
      display: inline-block;
      padding: 10px 50px;
      text-decoration: none;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      transition: all .7s ease;
      border-radius: 25px;
      background: #286FF7;
      border-width: 5px;
      border-color: transparent;
      width: 90%;
      color: #fff;
      
      &:hover {
        text-decoration: none;
      }
      
      @media screen and ${mq.minSm} {
        width: auto;
      }
    } 
  `;

const PartnerGrid = ({selected}) => {
 
  const data = useStaticQuery(graphql`
    query {
      filtered: allPartners(filter: {showOnSite: {eq: true}, featured: {eq: true}}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      all_regions_all_industries: allPartners(filter: {showOnSite: {eq: true}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      americas_all_industries: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Americas"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      americas_air: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Americas"}, type: {eq: "airline"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      americas_cruise: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Americas"}, type: {eq: "cruise"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      americas_rail: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Americas"}, type: {eq: "rail"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      americas_hospitality: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Americas"}, type: {eq: "hospitality"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      americas_finance: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Americas"}, type: {eq: "finance"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      apac_all_industries: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Asia-Pacific"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      apac_air: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Asia-Pacific"}, type: {eq: "airline"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      apac_cruise: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Asia-Pacific"}, type: {eq: "cruise"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      apac_rail: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Asia-Pacific"}, type: {eq: "rail"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      apac_hospitality: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Asia-Pacific"}, type: {eq: "hospitality"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
      apac_finance: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Asia-Pacific"}, type: {eq: "finance"}}, sort: {order: ASC, fields: name}) {
        nodes {
          readable
          website
          partnerLogo {
            ...PartnerLogoCanvas
          }
        }
      }
    europe_all_industries: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Europe"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    europe_air: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Europe"}, type: {eq: "airline"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    europe_cruise: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Europe"}, type: {eq: "cruise"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    europe_rail: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Europe"}, type: {eq: "rail"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    europe_hospitality: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Europe"}, type: {eq: "hospitality"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    europe_finance: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "Europe"}, type: {eq: "finance"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    mea_all_industries: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "MEA"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    mea_air: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "MEA"}, type: {eq: "airline"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    mea_cruise: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "MEA"}, type: {eq: "cruise"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    mea_rail: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "MEA"}, type: {eq: "rail"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    mea_hospitality: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "MEA"}, type: {eq: "hospitality"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    mea_finance: allPartners(filter: {showOnSite: {eq: true}, region: {eq: "MEA"}, type: {eq: "finance"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    all_regions_air: allPartners(filter: {showOnSite: {eq: true}, type: {eq: "airline"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    all_regions_cruise: allPartners(filter: {showOnSite: {eq: true}, type: {eq: "cruise"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    all_regions_rail: allPartners(filter: {showOnSite: {eq: true}, type: {eq: "rail"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    all_regions_hospitality: allPartners(filter: {showOnSite: {eq: true}, type: {eq: "hospitality"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }
    all_regions_finance: allPartners(filter: {showOnSite: {eq: true}, type: {eq: "finance"}}, sort: {order: ASC, fields: name}) {
      nodes {
        readable
        website
        partnerLogo {
          ...PartnerLogoCanvas
        }
      }
    }

  }
  `);



  //const input = (featured ? data.filtered : data.all );
  console.log("Selected is: " + selected);
  const input = (selected === undefined ? data.all_regions_all_industries : data[selected]);

  const partners = input.nodes;

  const linkElm = <Link className={'all-partners-link'} to={'/partners/'}>Explore all our partners</Link>


  return (
    <>
      <PartnerGridElement>
        <div className="container all-partners">

          {partners.length < 1 ?
            <p>No partners found</p> 
            
            :
          <div className="partners">
            { partners.map((partner, index) => {
              return <PartnerCard partner={partner} type={partner.type} key={index} />;
            })}
          </div>
        }
        </div>
      </PartnerGridElement>
    </>
  );
};

PartnerGrid.propTypes = {
  featured: PropTypes.bool,
}

PartnerGrid.defaultProps = {
  featured: true,
}

export default PartnerGrid;


export const query = graphql`
  fragment PartnerLogoCanvas on File {
    childImageSharp {
      fluid(maxWidth: 200, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
