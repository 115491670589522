import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { mq } from "../../../styles"
import GatsbyImage from "gatsby-image"
import PGLogo from "./PGLogo"

const LogoContainer = styled.div`
height: 22px;

a {
  display: inline-block;
  width: 150px;
  height: 22px;
}

  @media screen and (min-width: 992px) and (max-width: 1234px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 991px)  {
    margin-left: 20px;
  }
`;


const Logo = ({logo}) => {

    const logos = logo ? <PGLogo /> : <></>;

    return(
      <LogoContainer >
        <Link className={"logo-link-container"} to={typeof window != 'undefined' ? window.location.href.indexOf('/fr') > -1 ? '/fr/' : '/' : null} title={"Plusgrade.com"}>
          
        {logos}
          {/* { header ? <GatsbyImage fixed={headerQuery['plusgrade_logo'].childImageSharp.fixed} /> : <GatsbyImage fixed={headerQuery['footer_logo'].childImageSharp.fixed} /> } */}
        </Link>
      </LogoContainer>
    )
}

export default Logo
