/**
 * Return sign in or sign up URLs based on our environment.
 *
 * @see DPTS-1482
 *
 * @returns {{signInUrl: string, signUpUrl: string}}
 */
const getWalletUrls = () => {
  let signInUrl = 'https://wallet.points.com/#/login/sign-in';
  let signUpUrl = 'https://wallet.points.com/#/login/sign-up';

  if (isStaging()) {
    signInUrl = 'https://staging.loyaltywallet.io/#/login/sign-in';
    signUpUrl = 'https://staging.loyaltywallet.io/#/login/sign-up';
  }

  return {
    signInUrl,
    signUpUrl,
  }

}

/**
 * Rudimentary environment based values as the build is shared between staging/production.
 *
 * @returns {boolean} If we're on our staging environment or not.
 */
const isStaging = () => {

  const stagingEnvSubstrings = ['staging', 'localhost'];

  let result = false;

  if(typeof window !== 'undefined') {
    if (new RegExp(stagingEnvSubstrings.join("|")).test(window.location.href)) {
      result = true;
    }
  }

  return result;
}

export {
  isStaging,
  getWalletUrls
};