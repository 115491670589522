import GatsbyImage from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../button/Button";

const HospitalitySplitBlockCon = styled.div`

${ font.imports.sherika }

    
    max-width: 1400px;
    
    height: auto;
    display: flex;
    /*align-items: flex-start;*/
    align-items:center;
    justify-content: space-evenly;


    @media screen and ${mq.maxSm}{
        min-height: 700px;
      }
      @media screen and (max-width: 700px){
        min-height: 720px;
      }
      @media screen and (max-width: 630px) {
        min-height: 765px;
      }
      @media screen and (max-width: 500px) {
        min-height: 800px;
      }
      @media screen and (max-width: 450px) {
        min-height: 765px;
      }
      @media screen and (max-width: 415px) {
        min-height: 790px;
      }
      @media screen and (max-width: 381px) {
        min-height: 800px;
      }
      @media screen and (max-width: 350px) {
        min-height: 825px;
      }
      @media screen and (max-width: 345px) {
        min-height: 835px;
      }
      @media screen and (max-width: 330px){
        min-height: 855px;
      }

    

    @media only screen and ${mq.maxMd} {
        /*width: 95%;*/
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        /*margin: 0 60px auto;*/
    }

    .left-section {
        /*padding-top: 88px;*/
        flex-basis: 45%;
        max-width: 500px;
        text-align: center;
        margin-bottom: -12px;
        @media only screen and ${mq.maxMd} {
            flex-basis: 260px;
            width: 100%;
            max-width: 500px;
        }
    }

    .right-section {
        display:flex;
        flex-direction:column;
        justify-content:flex-end;

        @media only screen and ${mq.maxMd} {
            justify-content: center;
            padding-bottom: 40px;
            padding-top: 40px;
        }

        .icon-container {
            @media only screen and ${mq.maxMd} {
                margin: auto;
            }
        }

        .copy-con {

            @media only screen and ${mq.maxMd} {
                text-align: center;
            }
            font-family: ${font.family.sherika};
            h2 {
                color: ${brand.colors.pg_primary_dark};
                font-weight: 700;
                font-size: 36px;
                @media only screen and ${mq.maxMd} {
                    text-align: center;
                }
            }
            p {
                color: ${brand.colors.pg_primary_light};
                font-weight: 400;
                font-size: 23px;
                /*line-height: 22px;*/
                font-weight: bold;
                margin-bottom: 30px;
                max-width: 450px;
                @media only screen and ${mq.maxMd} {
                    text-align: center;
                }
            }

            ul {
                list-style-type: none;
                color: ${brand.colors.pg_secondary_dark};
                font-weight: 400;
                font-size: 20px;
                margin-left: 10px;
                max-width: 475px;
                margin-bottom: 60px;
                li {
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    justify-content: flex-start;
                    margin-bottom: 10px;

                    .bullet-icon-con {
                        flex-basis: 15px;
                        margin-right: 10px;
                    }
                    .bullet-copy {
                        line-height: 20px;
                    }
                }
            }

            /*
            .button-con > span {
              width: 200px;
              @media only screen and ${mq.maxMd} {
                  width: ${(props) => props.fr === true ? "220px" : "200px"};
              }
            }
            */
            span {
                margin: auto;

                @media only screen and (max-width: 500px) {
                    width: 225px;
                }
            }
            
        }
    }
`;

const HospitalitySplitBlock = ({title, copy, buttonLabel, buttonDestination, image, graphicStyle, fr, ...props}) =>{

  

    const compQuery = useStaticQuery(graphql`
        query {
            allHands: file(base: { eq: "GettyImages-901630488.jpg" }) {
                childImageSharp {
                    fixed(width: 500, height: 400, webpQuality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
            }
          
            mobileallHands: file(base: { eq: "GettyImages-901630488.jpg" }) {
                childImageSharp {
                    fixed(width: 1000, height: 400, webpQuality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
            }
            rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
                ...ButtonIcon
              }
        }
    `);

    return (
        <HospitalitySplitBlockCon fr {...props}>
            <div className="left-section">
            <GatsbyImage className={'aboutBg'} fixed={[compQuery['mobileallHands'].childImageSharp.fixed,
              {
                ...compQuery.allHands.childImageSharp.fixed,
                media: `(min-width: 991px)`,
              },
            ]}
         imgStyle={{objectFit: "cover", objectPosition: "center"}} />
            </div>

            <div className="right-section">

                <div className="copy-con">
                   
                    {
                        copy ? <p dangerouslySetInnerHTML={{__html: copy}}></p>

                        :

                        <p>
                            Offer Economy passengers more comfort and space by letting them block the seat—or even an entire row—next to them. With <strong>Seat Blocker</strong>, seats are “soft blocked,” generating revenue on unsold inventory and dynamically adapting seat allocation based on load factor and seat inventory.
                        </p>
                    }
                    
                    <div className="button-con">
                      <Button destination ={buttonDestination ? buttonDestination : '/contact/request-a-demo/'} label={ buttonLabel ? buttonLabel : 'Request a demo'} white orangeText pill/>
                    </div>
                </div>
            </div>
        </HospitalitySplitBlockCon>
    )
}

export default HospitalitySplitBlock;