import GatsbyImage from "gatsby-image";
import React, { useEffect, useState, useRef } from "react"
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import IconsList from "../icons/Icons";

const Testimonial = ({testimonials, background, imageStyle, title}) => {
    const pageQuery = useStaticQuery(graphql`
    query {
      testimonialIcon: file(base: { eq: "testimonial-white.png" }) {
        childImageSharp {
            fixed(width: 60, height: 52, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
    }
  `);

    const TestimonialContainer = styled.div`
    
    ${ font.imports.sherika }
        width: 100%;
        height: auto;
        min-height: 600px;
        position:relative;
        top: 0;
        left: 0;
        .background-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-height: 600px;
            filter: grayscale(1) contrast(1.2);
      
            &:after {
              content: '';
              position: absolute;
              z-index: 350;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background:linear-gradient(0deg, rgba(0, 4, 20, 0.65), rgba(0, 4, 20, 0.65));
            }
            .gatsby-image-wrapper {
              width: 100% !important;
              height: 100% !important;
            }
        }
    .testimonial-title {
        font-family: ${font.family.sherika};
        font-weight: 600;
        font-size: 56px;
        line-height: 1em;
        color: ${brand.colors.pg_orange};
        margin-top: 20px;
        
    }
        .content-container {
            position: relative;
            width: 95%;
            max-width: 800px;
            height: 100%;
            min-height: 600px;
            margin: auto;
            padding-top: 100px;
            padding-bottom: 50px;
            text-align: center;

            .testimonial {
                margin-top: 60px;
                margin-bottom: 20px;
                font-family: ${font.family.sherika};
                font-weight: 600;
                font-size: 28px;

                @media only screen and ${mq.maxMd} {
                    font-size: 22px;
                    font-weight: 400;
                  }
            }

            .signature {
                font-family: ${font.family.sherika};
                font-weight: 700;
                font-size: 20px;
                
                @media only screen and ${mq.maxMd} {
                    font-size: 18px;
                    font-weight: 400;
                  }
            }

            .testimonial-controls {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 95%;
                left: 50%;
                transform: translate(-50%, -50%);

                .control-left, .control-right {
                    color: ${brand.colors.pg_orange};
                    height: 26px;
                    cursor: pointer;

                    &:hover {
                        color: #fff;
                    }
                }

                div {
                    display: inline-block;
                }
            }
        }
    `;

    const [currentTestimonial, setCurrent] = useState(testimonials ? testimonials[0] : 'Default testimonial--Paul vR');
    let controlListInitialization = [];
    for (let x = 0; x < testimonials.length; x++){
        if (x == 0) {
            controlListInitialization.push(<TestControlCircle active />);
        }
        else {
            controlListInitialization.push(<TestControlCircle />);
        }
    }
    const [controlCircleList, setControlCircles] = useState(controlListInitialization);
    const currentTestimonialID = useRef(0);

    const moveRight = (() => {
        if(currentTestimonialID.current < testimonials.length -1) {
            setCurrent(testimonials[currentTestimonialID.current + 1]);
            let tempNewControlCircles = controlCircleList;
            tempNewControlCircles[currentTestimonialID.current] = <TestControlCircle />
            tempNewControlCircles[currentTestimonialID.current + 1] = <TestControlCircle active />

            setControlCircles(tempNewControlCircles);
            currentTestimonialID.current = currentTestimonialID.current + 1;
        }
    
      });

      const moveLeft = (() => {
        if(currentTestimonialID.current != 0) {
            setCurrent(testimonials[currentTestimonialID.current - 1]);
            let tempNewControlCircles = controlCircleList;
            tempNewControlCircles[currentTestimonialID.current] = <TestControlCircle />
            tempNewControlCircles[currentTestimonialID.current - 1] = <TestControlCircle active />

            setControlCircles(tempNewControlCircles);
            currentTestimonialID.current = currentTestimonialID.current - 1;
        }
    
      });

    return(
        <TestimonialContainer>
            <div className="background-container">
                <GatsbyImage fixed={background.childImageSharp.fixed} imgStyle={imageStyle} />
            </div>
            <div className="content-container">
                <div className="icon-container">
                    <GatsbyImage fixed={pageQuery['testimonialIcon'].childImageSharp.fixed} />
                </div>
                {title ? <p className="testimonial-title">{title}</p> : <></>}
                <p className="testimonial">{typeof window != 'undefined' ?  window.location.href.indexOf('/fr') > -1 ? '« ' + currentTestimonial.split('--')[0] + ' »' : '"' + currentTestimonial.split('--')[0] + '"' : '"' + currentTestimonial.split('--')[0] + '"' }</p>
                <p className="signature">{'-' + currentTestimonial.split('--')[1]}</p>
                <div className="testimonial-controls">
                    <div className="control-left" onClick={moveLeft}>{IconsList.chevronLeft}</div>
                        {controlCircleList}
                    <div className="control-right" onClick={moveRight}>{IconsList.chevronRight}</div>
                </div>
            </div>
        </TestimonialContainer>
    )
}

export default Testimonial;

const ControlCircle = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border: 1px solid ${props => props.active ? brand.colors.pg_orange : "#fff"};
    background-color: ${props => props.active ? brand.colors.pg_orange : "#fff"};
    margin: 0 7px;
`;

const TestControlCircle = ({...props}) => {

    return(
        <ControlCircle {...props}>
        </ControlCircle>
    )
}