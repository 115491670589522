import PropTypes from "prop-types";
import Logo from "./Logo";
import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import navData from "../../../../content/menu";
import frnavData from "../../../../content/frmenu";
import styled, { css } from "styled-components";
import Menu from "./Menu";
import { brand, mq, font } from "../../../styles";
import IconsList from "../../blocks/icons/Icons";

const HeaderElm = styled.header`
  width: 100%;
  margin: auto;
  height: 65px;
  z-index: 900;
  position: fixed;
  background: ${brand.colors.pg_primary_dark};
  @media only screen and (max-width: 1150px) {
    height: 100px;
    padding-top: 10px;
  }
  @media only screen and ${mq.maxMd} {
    height: 65px;
    padding-top: initial;
  }
  > div {
    width: 98%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background .7s ease;
    margin: 5px auto;

    
    @media only screen and (max-width: 1150px) {
      flex-direction: column;
    }

    @media only screen and ${mq.maxMd} {
      flex-direction: row;
    }
    @media screen and (max-width: 991px) {
        margin: 20px auto;
        width: 100%;
        display : inline-flex;
        align-items: flex-start;
      }
      @media only screen and (max-width: 400px) {
        flex-direction: column;
      }

      >div:first-of-type {
        @media only screen and (max-width: 1150px) {
          align-self: flex-start;
        }
      }
    button {
      // transition: all .5s ease;
      // background: ${brand.colors.pg_orange};
      text-decoration: none;
    }

    .menu-con-holder {
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 1150px) {
        align-self: flex-end;
      }

      @media only screen and ${mq.maxMd} {
        margin-right: 55px;
        width: 160px;
      }
    }
}
`;

const LangSelect = styled.div`
  ${font.imports.sherika};

  position: relative;
  padding: 0 20px;
  width: 125px;
  background-color: ${brand.colors.pg_primary_dark};
  pointer-events: all;
  cursor: pointer;

  @media only screen and ${mq.maxMd} {
    display: none;
  }

  .lang-option-title {
    color: #fff;
    font-family: ${font.family.sherika};
    font-weight: 800;
    font-size: 14px;
    &:hover {
      color: ${brand.colors.pg_orange};
    }
  }
`;

const LangExpandCon = styled.div`
  display: inline-block;
  height: auto;
  width: 200px;
  max-height: 0;
  position: absolute;
  left: 0;
  top: 30px;
  background-color: ${brand.colors.pg_primary_dark};
  overflow: hidden;
  padding: 0;
  @media only screen and (max-width: 400px) {
    top: 24px;
  }
  span {
    display: block;
    margin: 5px;
    color: #fff;
    font-family: ${font.family.sherika};
    font-weight: 800;
    font-size: 14px;
    padding: 10px 20px;

    a {
      text-decoration: none;

      &:hover {
        color: ${brand.colors.pg_orange};
      }
    }
  }

  ${props =>
    props.open &&
    css`
      max-height: 200px;
    `}
`;

const Header = ({ wallet }) => {
  const [open, setOpenState] = useState(false);
  const [top, setPos] = useState(false);
  const [langOpen, setLangOpen] = useState(false);
  const [langSwitchDestinationEnglish, setEnglishPageDestination] = useState(
    "/"
  );
  const [langSwitchDestinationFrench, setFrenchPageDestination] = useState(
    "/fr/"
  );
  let lightLogo = true;

  useEffect(() => {
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      setPos(scrolled >= 20);
    });
    document.addEventListener("touchmove", e => {
      let scrolled = document.scrollingElement.scrollTop;
      setPos(scrolled >= 20);
    });

    document.addEventListener("click", e => {
      let containsClickableClass = e.target.classList.contains(
        "lang-select-clickable"
      );
      if (containsClickableClass === false) {
        setLangOpen(false);
      }
    });

    if (typeof window !== "undefined") {
      let currentURL = window.location.href;
      let splitURL = currentURL.split("/");
      //let currentPage = splitURL[splitURL.length - 2];

      let currentPage = "";
      let enPage = "";
      let frPage = "";

      if (
        splitURL[splitURL.length - 2] == "hospitality" ||
        (splitURL[splitURL.length - 2] == "hotellerie" &&
          splitURL.length > "-1")
      ) {
        console.log("includes hosp");
        currentPage = splitURL[splitURL.length - 1];
      } else {
        console.log("does not inlcude hosp");
        currentPage = splitURL[splitURL.length - 2];
      }

      console.log({ currentPage });
      switch (currentPage) {
        //Switch cases to English
        case "fr":
          enPage = "";
          frPage = "";
          break;

        case "produits":
          enPage = "products/";
          frPage = "produits/";
          break;

        case "transport-aerien":
          enPage = "industries/air/";
          frPage = "industries/transport-aerien/";
          break;

        case "croisiere":
          enPage = "industries/cruise/";
          frPage = "industries/croisiere/";
          break;

        case "hotellerie":
          enPage = "industries/hospitality/";
          frPage = "industries/hotellerie/";
          break;

        case "upgrade":
          enPage = "industries/hospitality/upgrade/";
          frPage = "industries/hotellerie/upgrade/";
          break;

        case "stayextend":
          enPage = "industries/hospitality/stayextend/";
          frPage = "industries/hotellerie/stayextend/";
          break;

        case "stayplus":
          enPage = "industries/hospitality/stayplus/";
          frPage = "industries/hotellerie/stayplus/";
          break;

        case "integrations":
          enPage = "industries/hospitality/integrations/";
          frPage = "industries/hotellerie/integrations/";
          break;

        case "demo":
          enPage = "industries/hospitality/demo/";
          frPage = "industries/hotellerie/demonstration/";
          break;

        case "rail":
          enPage = "industries/rail/";
          frPage = "industries/rail/";
          break;

        case "partenaires":
          enPage = "partners/";
          frPage = "partenaires/";
          break;

        case "a-propos-de":
          enPage = "about/";
          frPage = "a-propos-de/";
          break;

        case "carrieres":
          enPage = "careers/";
          frPage = "carrieres/";
          break;

        case "demandez-une-demonstration":
          enPage = "contact/request-a-demo/";
          frPage = "contact/demandez-une-demonstration/";
          break;

        case "equipe-de-direction":
          enPage = "about/executive-lead-team/";
          frPage = "a-propos-de/equipe-de-direction/";
          break;

        case "contact":
          enPage = "contact/";
          frPage = "contact/";
          break;

        case "politique-de-temoins":
          enPage = "policy/cookie-policy/";
          frPage = "politique/politique-de-temoins/";
          break;

        case "politique-de-confidentialite":
          enPage = "policy/privacy/";
          frPage = "politique/politique-de-confidentialite/";
          break;

        //Switch cases to French
        case "":
          frPage = "";
          enPage = "";
          break;

        case "products":
          frPage = "produits/";
          enPage = "products/";
          break;

        case "air":
          frPage = "industries/transport-aerien/";
          enPage = "industries/air/";
          break;

        case "cruise":
          frPage = "industries/croisiere/";
          enPage = "industries/cruise/";
          break;

        case "hospitality":
          frPage = "industries/hotellerie/";
          enPage = "industries/hospitality/";
          break;

        case "upgrade":
          frPage = "industries/hotellerie/upgrade/";
          enPage = "industries/hospitality/upgrade/";
          break;

        case "stayextend":
          frPage = "industries/hotellerie/stayextend/";
          enPage = "industries/hotellerie/stayextend/";
          break;

        case "stayplus":
          frPage = "industries/hotellerie/stayplus/";
          enPage = "industries/hospitality/stayplus/";
          break;

        case "integrations":
          frPage = "industries/hotellerie/integrations/";
          enPage = "industries/hospitality/integrations/";
          break;

        case "demo":
          frPage = "industries/hotellerie/demonstration";
          enPage = "industries/hospitality/demo/";
          break;

        case "rail":
          frPage = "industries/rail/";
          enPage = "industries/rail/";
          break;

        case "partners":
          frPage = "partenaires/";
          enPage = "partners/";
          break;

        case "about":
          frPage = "a-propos-de/";
          enPage = "about/";
          break;

        case "careers":
          frPage = "carrieres/";
          enPage = "careers/";
          break;

        case "request-a-demo":
          frPage = "contact/demandez-une-demonstration/";
          enPage = "contact/request-a-demo/";
          break;

        case "executive-lead-team":
          enPage = "about/executive-lead-team/";
          frPage = "a-propos-de/equipe-de-direction/";
          break;

        case "contact":
          enPage = "contact/";
          frPage = "contact/";
          break;

        case "cookie-policy":
          enPage = "policy/cookie-policy/";
          frPage = "politique/politique-de-temoins/";
          break;

        case "privacy":
          enPage = "policy/privacy/";
          frPage = "politique/politique-de-confidentialite/";
          break;
      }
      setEnglishPageDestination("/" + enPage);
      setFrenchPageDestination("/fr/" + frPage);
    }
  }, []);

  const data =
    typeof window != "undefined"
      ? window.location.href.indexOf("/fr") > -1
        ? frnavData
        : navData
      : navData;

  const Headerlogo = useStaticQuery(graphql`
    query {
      plusgrade_logo: file(base: { eq: "pg_logo_2023.png" }) {
        childImageSharp {
          fixed(width: 140, height: 22, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <HeaderElm className="1" affixed={top} open={open}>
      <div>
        <Logo className="2" logo={Headerlogo["plusgrade_logo"]} />
        <MenuToggle
          open={open}
          affixed={top}
          onFocus={() => setOpenState(!open)}
          onClick={() => setOpenState(!open)}
          aria-label={"Menu toggle button"}
        >
          <span />
          <span />
          <span />
          <span />
        </MenuToggle>
        <div className="menu-con-holder">
          <Menu
            data={data}
            open={open}
            affixed={top}
            enTo={langSwitchDestinationEnglish}
            frTo={langSwitchDestinationFrench}
          />
          <LangSelect
            onClick={() => setLangOpen(!langOpen)}
            className="lang-select-clickable"
          >
            <span className="lang-select-clickable lang-option-title">
              {typeof window != "undefined"
                ? window.location.href.indexOf("/fr") > -1
                  ? "Langue"
                  : "Language"
                : null}{" "}
              {IconsList.chevronDown}
            </span>
            <LangExpandCon open={langOpen} className="lang-select-clickable">
              <span className="lang-select-clickable lang-option en">
                <Link to={langSwitchDestinationEnglish}>English (en-CA)</Link>
              </span>
              <span className="lang-select-clickable lang-option fr">
                <Link to={langSwitchDestinationFrench}>Français (fr-CA)</Link>
              </span>
            </LangExpandCon>
          </LangSelect>
        </div>
      </div>
    </HeaderElm>
  );
};

Header.propTypes = {
  navData: PropTypes.bool,
};

Header.defaultProps = {
  navData: false,
};

export default Header;

const MenuToggle = styled.button`
  display: block;
  background: transparent;
  padding: 10px 8px;
  border-radius: 35px;
  border: 0;
  margin-left: 1rem;
  position : absolute;
  right: 3%;
  top: 17%;
  ${props =>
    props.open &&
    css`
      top: 17%;
    `}
  
  span {
    height: 2px;
    display: block;
    width: 20px;
    border-radius: 2px;
    background: #1B64EF;
    transition: all .5s ease;
    
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  
  &:hover span {
    background: #1B64EF;
  }
  
  ${props =>
    props.affixed &&
    css`
      span {
        background: #1b64ef;
      }
    `}
  
  ${props =>
    props.open &&
    css`
      span {
        background: #1b64ef;
      }
    `}
 
  span:nth-child(3) { margin-top: -7px; }
  
  ${props =>
    props.open &&
    css`
      span:nth-child(1) {
        opacity: 0;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
      }
      span:nth-child(4) {
        opacity: 0;
      }
    `}
    
  @media screen and ${mq.minMd} {
    display: none;
  }
`;
