import GatsbyImage from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { brand, mq } from '../../../styles';
import { graphql } from "gatsby";

const pg_blue = brand.colors.pg_blue;
const pg_dark = brand.colors.pg_primary_dark;

const ImageContentContainer = styled.div`
    width: 100%;
    max-width: 1100px;
    margin: auto;
    display: flex;
    background-color: ${props => props.blueBG ? pg_blue : pg_dark};
    color: #fff;

    .detail-container {
        flex-basis: 70%;
        padding: 30px 80px;

        .detail-content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
        }
    }

    .image-container {
        flex-basis: 30%;
        margin-bottom: -7px;

        .gatsby-image-wrapper {
          height: 100% !important;
        }
    }

`;

const ImageContent = ({icon, title, image, alt, children, blueBG}) =>{
    let iconElement = icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} />: <></>;
    let backgroundImage = image ? <GatsbyImage fixed={image} alt = {alt} /> : <></>;

    return(
        <ImageContentContainer blueBG = {blueBG}>
            <div className="detail-container">
                { iconElement }
                <h3>{title}</h3>
                <div className="detail-content">
                    { children }
                </div>
            </div>
            <div className="image-container">
                {backgroundImage}
            </div>
        </ImageContentContainer>
    )
}

export default ImageContent;

export const query = graphql`
  fragment ContentSideImage on File {
    childImageSharp {
      fixed(width: 330, height: 550, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
      }
    }
  },
  fragment ContentSideIcon on File {
    childImageSharp {
      fixed(width: 50, height: 50, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;