import GatsbyImage from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import  Button  from "../button/Button"

const PremUpgradeContainer = ({copy, image, graphicStyle, fr, buttonURL, buttonLabel, buttonIcon, ...props}) => {
    const btn =  ( buttonURL ? <Button destination={buttonURL} label={buttonLabel} icon={buttonIcon} orange whiteText pill /> : <></>);

    const pageQuery = useStaticQuery(graphql`
        query{
            premUpgradeIcon: file(base: { eq: "prem_upgrade_icon_orange.png" }) {
                childImageSharp {
                    fixed(width: 50, height: 50, webpQuality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                }
        }
        mobileDevice: file(base: { eq: "Product-Premium-Upgrade.png" }) {
            childImageSharp {
                fluid(maxWidth: 400, webpQuality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
        }

        rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
            ...ButtonIcon
          }
    }
    `);

    const UpgradeContainer = styled.div`

${ font.imports.sherika }

        display: flex;
        flex-direction:row;
        align-items: flex-start;
        justify-content: center;
        width: 95%;
        max-width: 1400px;
        margin: 100px auto 0;
        min-height: 350px;
        padding-bottom: ${(props) => props.fr ? "60px" : "0"};
        @media only screen and ${mq.maxMd} {
            flex-direction: column;
            align-items: center;
        }

        .upgrade-left-con {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex-basis: 50%;
            margin-top: -65px;

            @media only screen and ${mq.maxMd} {
                width: 95%;
                max-width: 400px;
                align-items: center;
                text-align: center;
            }
            h3 {
                font-family: ${font.family.sherika};
                font-weight: 600;
                font-size: ${(props) => props.fr ? "40px" : "46px"};
                color: ${brand.colors.pg_primary_dark};
                span {
                    display: block;
                }
            }
            p {
                color: #828282;
                font-size: 22px;

            }

            span {
                @media only screen and ${mq.maxMd} {
                    width: 300px;
                }
            }
        }

        .upgrade-right-con {
            flex-basis: 40%;
            height: 350px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media only screen and ${mq.maxMd} {
                margin-left: 20px;
                margin-top: 40px;
                width: 95%;
                max-width: 450px;
                justify-content: center;
            } 
            
            .gatsby-image-wrapper {
                height: 100% !important;
                width: 100% !important;
                max-width: 280px;

                @media only screen and ${mq.maxMd} {
                    height: 360px !important;
                }
            }
        }
    `;

    return(
        <UpgradeContainer fr {...props}>
            <div className="upgrade-left-con">
                <h3 style={{fontSize: fr ? "40px" : "46px"}}><GatsbyImage fixed={pageQuery['premUpgradeIcon'].childImageSharp.fixed} /><span>{fr ? 'Surclassement Premium' : 'Premium Upgrade'}</span></h3>
                <p dangerouslySetInnerHTML={{__html: copy}}></p>
                <Button destination={fr ? '/fr/contact/demandez-une-demonstration/' : '/contact/request-a-demo'} label={fr ? 'Demander une démonstration' : 'Request a demo'} icon={pageQuery['rightArrowIcon']} orange whiteText pill />
                { btn ? btn : <></> }
            </div>
            <div className="upgrade-right-con">
                {/* <GatsbyImage fixed={pageQuery['mobileDevice'].childImageSharp.fixed} imgStyle={{objectFit: "contain"}} /> */}
                <GatsbyImage fluid={ image ? image.childImageSharp.fluid : pageQuery['mobileDevice'].childImageSharp.fluid} imgStyle={graphicStyle} />
            </div>
        </UpgradeContainer>
    )
}

export default PremUpgradeContainer