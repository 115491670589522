import GatsbyImage from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../button/Button";
import IconsList from "../icons/Icons";

const HospitalityPointsLoyaltyCon = styled.div`

${ font.imports.sherika }

width: 95%;
max-width: 1400px;
margin: 60px auto;
height: auto;
min-height: 410px;
display: flex;
align-items: flex-start;
flex-direction: row-reverse;
justify-content: space-evenly;
padding-top: 60px;

@media only screen and ${mq.maxMd} {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
}

.left-section {
    /*padding-top: 88px;*/
    flex-basis: 45%;
    max-width: 500px;
    text-align: center;
    @media only screen and ${mq.maxMd} {
        flex-basis: 260px;
        width: 95%;
        max-width: 500px;
    }
}

.right-section {
    display:flex;
    flex-direction:column;
    justify-content:flex-end;

    @media only screen and ${mq.maxMd} {
        justify-content: center;
        padding-bottom: 40px;
    }

    .icon-container {
        @media only screen and ${mq.maxMd} {
            margin: auto;
        }
    }

    .copy-con {

        @media only screen and ${mq.maxMd} {
            text-align: center;
        }
        font-family: ${font.family.sherika};
        h2 {
            color: ${brand.colors.pg_primary_dark};
            font-weight: 700;
            font-size: 36px;
            white-space: pre-wrap;
            @media only screen and ${mq.maxMd} {
                text-align: center;
            }
        }
        p {
            color: ${brand.colors.pg_secondary_dark};
            font-weight: 400;
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 30px;
            max-width: 450px;
            @media only screen and ${mq.maxMd} {
                text-align: center;
            }
        }

        ul {
            list-style-type: none;
            color: ${brand.colors.pg_secondary_dark};
            font-weight: 400;
            font-size: 20px;
            margin-left: 10px;
            max-width: 475px;
            margin-bottom: 60px;
            li {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: flex-start;
                margin-bottom: 10px;

                .bullet-icon-con {
                    flex-basis: 15px;
                    margin-right: 10px;
                }
                .bullet-copy {
                    line-height: 20px;
                }
            }
        }

        /*
        .button-con > span {
          width: 200px;
          @media only screen and ${mq.maxMd} {
              width: ${(props) => props.fr === true ? "220px" : "200px"};
          }
        }
        */

        span {
            margin: auto;

            @media only screen and (max-width: 500px) {
                width: 225px;
            }
        }
        
    }
}
`;

const HospitalityPointsLoyalty = ({title, copy, buttonDest, buttonLabel, image, graphicStyle, fr,  ...props}) =>{

    const compQuery = useStaticQuery(graphql`
        query {
            productGraphic: file(base: { eq: "Hospitality%20-%20Loyalty%20Commerce%20Platform.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500, webpQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

        
            loyaltyIcon: file(base: {eq: "speedPassIcon.png"}){
                childImageSharp {
                    fixed(width: 51, height: 42, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            circleCheckmark: file(base: {eq: "circle-checkmark.png"}){
                childImageSharp {
                    fixed(width: 16, height: 15, webpQuality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
            rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
                ...ButtonIcon
              }
        }
    `);

    const bulletIcon = <GatsbyImage fixed={compQuery['circleCheckmark'].childImageSharp.fixed} />;

    return (
        <HospitalityPointsLoyaltyCon fr {...props}>
            <div className="left-section">
                <GatsbyImage fluid={compQuery['productGraphic'].childImageSharp.fluid} />
            </div>

            <div className="right-section">
                <div className="icon-container">
                    <GatsbyImage fixed={compQuery['loyaltyIcon'].childImageSharp.fixed} />
                </div>
                <div className="copy-con">
                    <h2>
                        {title ? title : 'Loyalty Platform Partnerships'}
                    </h2>

                {
                    copy ? <p dangerouslySetInnerHTML={{__html: copy}}></p> :
                    <>
                    <p>
                        {
                            fr === false ? 
                            
                            'Give everyday passengers the option to pay to access priority check-in and boarding, and navigate the airport stress-free with access to expedited security lanes. Leverage existing integrations and travel touchpoints to monetize and improve the customer journey.'

                            :

                            'Donnez aux passagers de tous les jours la possibilité de payer pour accéder à l\'enregistrement et à l\'embarquement prioritaires, et de naviguer dans l\'aéroport sans aucun stress en leur donnant l\'accès aux voies accélérées pour passer les contrôles de sécurité. Tirez profit des intégrations existantes et des points de contact des voyageurs pour monétiser et améliorer leur parcours client.'
                        }
                    </p>
                    <p dangerouslySetInnerHTML={{__html: fr === false ?
                            'The perks passengers get with <strong>SpeedPass</strong>:'

                            :

                            'Les bénéfices de <strong>SpeedPass</strong> pour les passagers :'
                        }}>
                    </p>
                    <ul>
                        <li>
                            <span className="bullet-icon-con">{bulletIcon}</span> <span className="bullet-copy">{fr === true ? 'Enregistrement prioritaire, dépôt des bagages et vérification des documents ' : 'Priority check-in, baggage drop, and documents check'}</span>
                        </li>
                        <li>
                            <span className="bullet-icon-con">{bulletIcon}</span> <span className="bullet-copy">{ fr === true ? 'Voies accélérées aux contrôles de sécurité' : 'Expedited security'}</span>
                        </li>
                        <li>
                            <span className="bullet-icon-con">{bulletIcon}</span> <span className="bullet-copy">{ fr === true ? 'Embarquement prioritaire' : 'Priority boarding'}</span>
                        </li>
                    </ul>
                    </>
                }
                    <div className="button-con">
                        <Button destination ={ buttonDest ? buttonDest : '/contact/request-a-demo'} label={ buttonLabel ? buttonLabel : 'Request a demo'} target={'_blank'}  faIcon={IconsList.external}orange whiteText pill/>
                    </div>
                </div>
            </div>
        </HospitalityPointsLoyaltyCon>
    )
}

export default HospitalityPointsLoyalty;