import GatsbyImage from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";

const HospitalityGridCon = styled.div`

${ font.imports.sherika }
`;

const HospitalityGrid = ({addLineOne, addLineTwo, addLineThree, title, copy, buttonLabel, buttonDestination, icon, graphicStyle, fr,  ...props}) =>{
  
  const HospitalityGrid = styled.div`
  
  ${ font.imports.sherika }
    /*flex-basis: 24%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid pink;
    padding: 1.5em 0 1em 0;
    margin:0;*/
    display: grid;
    min-height:140px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1em 0;


    background-color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      flex-basis: 95%;
    }
  
    .icon-con {
      display: inherit;
      margin: 0 auto;
      @media only screen and ${mq.maxMd} {
        text-align: center;
        margin: auto;
      }
  
    }
  
    .headline-con {

      padding:0;
      margin:0;
      @media only screen and ${mq.maxMd} {
        text-align: center;
        margin: auto;
      }
    }
  


    p{
      margin-top: 1px;
      font-family: ${font.family.sherika};
      font-weight: 700;
      font-size: 16px;
      padding:0;
      margin:0;
      line-height: 20px;
      color: ${brand.colors.pg_primary_light};
      @media only screen and ${mq.maxMd} {
        text-align: center;
      }
    }

    ${props => props.dropShadow && css`
      box-shadow: 0px 8px 16px -2px #0000000A;
    `}
  `;

  return(
    <HospitalityGrid fr {...props}>

      <div className="icon-con">
        <GatsbyImage fixed={icon.childImageSharp.fixed} />
      </div>
      {title? 
        <div className="headline-con">
          <p dangerouslySetInnerHTML={{__html: title}}>
          </p>
        </div>
      :
      ""
      }
    </HospitalityGrid>
  )
}

export default HospitalityGrid;