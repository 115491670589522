import React from "react";
import styled, { css } from "styled-components"
import PropTypes from "prop-types";
import { brand, mq } from '../../../styles';
import GatsbyImage from "gatsby-image";
import { graphql } from "gatsby"
import Button from "../button/Button";

const Container = styled.div`
  position: relative; 
  width: 95%;
  height: 450px;
  max-height: 450px;
  margin: auto;

  @media only screen and ${mq.maxMd} {
    text-align: center;
  }

  div {
    display: inline-block;
  }

  .hero-copy-container {
    position: absolute;
    z-index: 200;
    width: 30%;
    height: 100%;
    background-color: ${brand.colors.pg_primary_dark};
    display: flex;
    align-items: flex-end;

    &:after {
      content: '';
      position: absolute;
      z-index: 100;
      left: 99%;
      top: 8px;
      width: 0px;
      height: 444px;
      border-style: solid;
      border-width: 444px 0 0 410px;
      border-color: transparent transparent transparent ${brand.colors.pg_primary_dark};
      transform: rotate(0deg);
    }

    .hero-copy {
      position: absolute;
      z-index: 300;
      left: 10%;
      width: 400px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
      color: #fff;

      h1 {
        font-size: 50px;
        white-space: pre-wrap;

        @media only screen and ${mq.maxMd} {

          font-size: 32px;
        }
      }

      .headline-icon {
        margin-right: 10px;
        @media only screen and ${mq.maxMd} {
          margin-right: 0;
        }
      }
    }

    
  }

  ${props => props.noImage && css`

  height: 350px;
  margin-bottom: 70px;
  max-width: 1400px;

  .hero-copy-container {
    width: 100%;
    align-items: flex-end;
    &:after {
      display: none;
    }
    .hero-copy {
      width: 100%;
      left: 0;
      flex-direction: column;
      justify-content: space-between;

      @media only screen and ${mq.maxMd} {
        align-items: center;
      }
      h1 {
        font-size: 75px;
        margin-bottom: 30px;

        @media only screen and ${mq.maxMd} {
          font-size: 32px;
        }
      }
      h2 {
        color: ${brand.colors.pg_orange};
        font-size: 56px;
        
        @media only screen and ${mq.maxMd} {
          font-size: 26px;
        }
      }
      p {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .headline-icon {
      display: none;
    }
  }
`} 

  .hero-image-container {
    float: right;
    margin-bottom: -7px;
  }


${props => props.fullBG && css`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin: auto;
  height: 550px;
  max-height: 550px;

  .hero-copy-container {
    width: 100%;
    margin: auto;
    z-index: 400;
    align-items: center;
    justify-content: flex-start;
    background: transparent;

    &:after {
      display: none;
    }
    .hero-copy {
      position: initial;
      width: 90%;
      max-width: 1400px;
      margin: auto;
      padding-top: 100px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      @media only screen and ${mq.maxMd} {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .headline-icon {
        flex: 1;
        flex-basis: 40px;
        .gatsby-image-wrapper {
          margin-top: 15px;
        }
      }
      h1 {
        font-size: 75px;
        margin-bottom: 30px;
        flex: 1;
        flex-basis: 92%;
        @media screen and ${mq.maxMd} {
          font-size: 40px;
          text-align: center;
        }

        .desktop-break{
          display: block;
          @media only screen and ${mq.maxMd} {
            display: none;
          }
        }

        .mobile-space {
          display: none;
          margin: 0;
          @media only screen and ${mq.maxMd} {
            display: inline;
          }
        }
      }
      h2 {
        color: ${brand.colors.pg_orange};
        font-size: 26px;
        max-width: 700px;
        flex: 2;
        flex-basis: 100%;
        margin-left: 50px;
        text-shadow: 0 0 20px ${brand.colors.pg_primary_dark};
        @media only screen and ${mq.maxMd} {
          margin-left: 0;
        }
        @media only screen and (max-width: 400px) {
          font-size: 22px;
        }



      }
      p {
        font-size: 20px;
        font-weight: 700;
      }
      span {
        flex-basis: 100%;
        margin-left: 22px;
        @media only screen and ${mq.maxMd} {
          margin-left: 0;
        }
      }

      .no-indent {
        margin-left: 0;

        & > * {
          margin-left: 0;
        }
      }

    }
  }

  .hero-image-container {
    position:absolute;
    top: 0;
    left: 0;
    z-index: 300;
    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
      height: 100% !important;
      width: 100% !important;
    }
  }
`}


${props => props.scaleIconHack && css`
  .headline-icon {
    .gatsby-image-wrapper {
      border:1px solid pink;
      width:45px;
      height:50px;
    }
  }
`}

${props => props.backgroundGradient && css`
  filter: grayscale(1) contrast(1.3);

  &:after {
    content: '';
    position: absolute;
    z-index: 350;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0.7231267507002801) 10%, rgba(0,0,0,0.5508578431372548) 49%, rgba(0,0,0,0) 100%);
}

`}
`;



/**
 * This is our page header component.
 */
const Hero = ({ image, title, subtitle, copy, icon, alt, buttonURL, iconStyle, buttonLabel, buttonIcon, imageStyle, ...props } ) => {

  const img = (image ? <GatsbyImage fixed={image.childImageSharp.fixed} alt={alt} image={image} imgStyle={ imageStyle ? imageStyle : {objectPosition: "center bottom"}}/> : ''  );
  const ic = (icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} imgStyle={{objectFit: "contain"}}/> : '');
  const btn =  ( buttonURL ? <Button destination={buttonURL} label={buttonLabel} icon={buttonIcon} orange whiteText pill /> : <></>);
  return (

    <Container {...props}>
      <div className={'hero-copy-container'}>
        <div className={'hero-copy'}>
          {
            ic ? 
            <div className="headline-icon">
              { ic }
            </div>

            :

            <></>
          }
          
          <h1 dangerouslySetInnerHTML={{__html: title}} className={ic ? "" : "no-indent"}></h1>
          <h2 dangerouslySetInnerHTML={{__html: subtitle}} className={ic ? "" : "no-indent"}></h2>
          <p dangerouslySetInnerHTML={{__html: copy}} className={ic ? "" : "no-indent"}></p>
          <span className={ic ? "" : "no-indent"}>
            { btn ? btn : <></> }
          </span>
        </div>
      </div>
      <div className={'hero-image-container'}>
        { img ? img : <></> }
      </div>
    </Container>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  align: PropTypes.string,
  image: PropTypes.object,
};

Hero.defaultProps = {
  align: "center center",
};

export default Hero;

export { Hero };



export const query = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fixed(width: 726, height: 450, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
      }
    }
  },
  fragment HeroIcon on File {
    childImageSharp {
      fixed(width: 50, height: 30, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  fragment FullHeroBackground on File{
    childImageSharp {
      fixed(width: 1100, height: 450, webpQuality: 100){
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
