import GatsbyImage from "gatsby-image";
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { brand, mq } from '../../../styles';
import { graphql } from "gatsby";

const IconBlockContainer = styled.div`
    flex-basis: 45%;
    p{margin-top: 20px;}
`;



const IconBlock = ({icon, title, copy, children, ...props}) => {
const IC = icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} />: <></>;
    return(
        <IconBlockContainer {...props}>
            {IC}
            {title ? <h4>{title}</h4> : ''}
            <p>
                {copy}
            </p>
            {children}
        </IconBlockContainer>
    )
}
IconBlock.propTypes = {
  contained: PropTypes.bool
};

IconBlock.defaultProps = {
  contained: true
};

export default IconBlock;

export const query = graphql`
  fragment ContentBlockIcon on File {
    childImageSharp {
      fixed(width: 40, height: 40, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;


