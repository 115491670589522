import { createGlobalStyle } from "styled-components";
import { mq, brand, font } from "./variables"
import reset from "./utilities/reset"

export default createGlobalStyle`

  ${ reset }

  ${ font.imports.nunito } 
  ${ font.imports.museoSans }
  
  ${ font.imports.sherika }
  

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${font.family.sherika};
    position: relative;
    color: #333333;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-size: 18px;
    background-color: ${brand.colors.pg_primary_dark};
  }
  .header-banner{
    // height:200px;
  }
  .border {
    width: 100%;
    max-width: 800px;
    border-top: 1px solid #595959;
  }

  div[data-partner-name="World of Hyatt"] {
   > a > div.gatsby-image-wrapper > div {
    padding-bottom: 46% !important;
   }
  }
  .container {
    margin: 0 auto;
    width: 100%;
    position: relative;
  
    &-padding {
      position: relative;
      padding-left: 1rem;
      padding-right: 1rem;
  
      @media screen and ${mq.minMd} {
        padding-left: calc(((100vw - 1120px) / 2) + 1.0875rem);
        padding-right: calc(((100vw - 1120px) / 2) + 1.0875rem);
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    font-weight: 800;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
  }
  
  
  
  
  
  blockquote {
    border-left: 5px solid #ccc;
    padding-left: 10px;
  }
  blockquote > * {
    margin-bottom: 0;
  }
  
  .ff-mono { font-family: monospace; }
  .ff-serif { font-family: serif; }
  
  
  .grid {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    @media screen and ${mq.minMd} {
      flex-direction: row;
      justify-content: space-between;
    }
    
    &-3x {
      @media screen and ${mq.minMd} {
        .col,
        > div {
          width: 32%;
        }
      } 
    }
    &.index {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: nowrap;
      @media screen and ${mq.maxSm} {
        display: flex;
    flex-direction: column;
    flex-wrap: wrap;
      }
    }
    &-2x {
      @media screen and ${mq.minMd} {
        .col,
        > div {
          width: 48%;
        }
      }
    }
    
    &.offset {
      >:nth-child(even) {
        transform: translateY(20px);
      }
    }
  } 
  
  .max-w-none { 	max-width: none; }
  .max-w-xs { 	max-width: 20rem; }
  .max-w-sm { 	max-width: 24rem; }
  .max-w-md { 	max-width: 28rem; }
  .max-w-lg { 	max-width: 32rem; }
  .max-w-xl { 	max-width: 36rem; }
  .max-w-2xl { 	max-width: 42rem; }
  .max-w-3xl { 	max-width: 48rem; }
  .max-w-4xl { 	max-width: 56rem; }
  .max-w-5xl { 	max-width: 64rem; }
  .max-w-6xl { 	max-width: 72rem; }
  .max-w-full { 	max-width: 100%; }
  
  a {
    text-decoration: none;
    color: inherit;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .gatsby-image-wrapper {
    max-width: 100%;
  }



  .secondary-hero-bg-image: after {
    content: "";
    position: absolute;
    z-index: 300;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0.7231267507002801) 10%, rgba(0,0,0,0.5508578431372548) 49%, rgba(0,0,0,0) 100%);

  }
  .premium-upgrade-bg:before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 200;
    background-image: url('./assets/prem_upgrade_bg.jpg');
    background-size: cover;
    background-position: 50% 60%;
    background-repeat: no-repeat;
    filter: grayscale(1) contrast(1.1);
  }


	.st0{display:none;}
	.st1{display:inline;fill:#D87777;}
	.st2{display:inline;}
	.st3{fill:#D87777;}
	.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#D87777;}
	.st5{fill:#fff;}
	.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#fff;}
	.st7{fill-rule:evenodd;clip-rule:evenodd;fill:#1B64EF;}

`;

