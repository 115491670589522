import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components"
import { brand, mq, font } from "../../../styles"
import GatsbyImage from "gatsby-image"
import { graphql } from "gatsby";


const CallToActionElm = styled.section`
${props => props.aboutUs && css `
height: 100% !important;
// .container{
//         width: 90% !important;
// }
.content-container{
        position: static !important;
        transform: none !important;
        padding: 50px !important;
        .inner{
                margin: 0 !important;
				@media screen and ${mq.maxSm} {
					span{
						width: 250px !important;
					}
				}
        }
		@media screen and ${mq.maxSm} {
			padding: 0px 25px !important;
			
		}
}
`}

${props => props.primaryBlueBg && css `
	background: ${brand.colors.pg_blue} !important;
`}

${props => props.primaryLightBg && css `
background: ${brand.colors.pg_primary_light} !important;


height: 100% !important;

.content-container{
	h4 {
		color: ${brand.colors.pg_orange};
	}

	p {
		color: ${brand.colors.pg_primary_dark};
		white-space: pre-wrap;
	}
}
`}

${props => props.responsiveButton && css `
	@media screen and ${mq.maxSm} {
		.content-container{
			.inner{
				span{
					width: 250px !important;
					}
				}
		}
	}
`}


${ font.imports.sherika }
	position: relative;
	color: #fff;
	background: ${brand.colors.pg_blue};
	width: 100%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 90px 0;
	
	.container {
	  width: 100%;
	  max-width: 1400px;
	  margin: auto;

	  @media only screen and ${mq.maxMd} {
		width: 95%;
	  }
	  @media screen and ${mq.maxSm}{
		margin: auto 10%;
	  }
	}
	.content-container {

		h4 {
			margin-bottom: 15px;
			font-family: ${font.family.sherika};
			font-size: 27px;
			font-weight: 600;
			text-align: center;
			
			@media screen and ${mq.minMd} {
			  font-size: 50px;
			  white-space: pre-wrap;
			}
			
		}

		p {
			text-align: center;
			font-family: ${font.family.sherika};
			font-size: 20px;
			font-weight: 400;
		}

		.inner {
			margin-top: 40px;
			text-align: center;

			span {
				@media only screen and ${mq.maxMd} {
					width: 300px;
				}
			}
		}
	}
	
`;

const CallToAction = ({ children, title, subtitle, ...props }) => {

  return (
    <>
      <CallToActionElm {...props}>

        <div className="container">
			<div className="content-container">
				<h4>{ title }</h4>
				<p>{ subtitle }</p>
          		<div className="inner">{children}</div>
			</div>
          
        </div>
      </CallToActionElm>
    </>
  );
};

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

CallToAction.defaultProps = {
  type: `basic`,
  background: `to-king-street.jpg`,
};

export default CallToAction;

export const query = graphql`
  fragment CTABackground on File {
    childImageSharp {
      fixed(width: 1500, height: 512, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
