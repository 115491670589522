import React from "react";
import styled, { css } from "styled-components"
import PropTypes from "prop-types";
import { brand, mq } from '../../../styles';
import GatsbyImage from "gatsby-image";
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image";
import IconsList from "../icons/Icons";

const Container = styled.div`
    width: 100%;
    max-width: 1100px;
    height: 300px;
    margin: auto;

    .secondary-hero-bg-image {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 25px;

        .secondary-hero-copy {
            position: relative;
            z-index: 350;
            width: 30%;
            display: flex;
            flex-direction: row;

            .secondary-hero-icon {
                filter: invert(38%) sepia(78%) saturate(4413%) hue-rotate(6deg) brightness(101%) contrast(101%);
                bottom: 0;
            }
            h1 {
                color: ${brand.colors.pg_orange};
                font-weight: 800;
                font-size: 60px;
                margin: 0 0 20px 20px;
            }
        }

        .aviation-icon {
            position: relative;
            left: 100px;
            z-index: 450;

            .icon-copy {
                color: #fff;
            }
        }
    }
`



/**
 * Secondary Hero Component, to update bg image, pass bgClassName as component prop, add background-image css to styles/global.js
 */
const SecondaryHero = ({title, alt, bgClassName, icon } ) => {


const ic = (icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} /> : '');

  return (

    <Container>
        <div className={'secondary-hero-bg-image ' + bgClassName}>
            <div className={'secondary-hero-copy'}>
                <div className={'secondary-hero-icon'}>
                    { ic }
                </div>
                <h1 dangerouslySetInnerHTML={{__html: title}}></h1>
            </div>
            <div className="aviation-icon">
                {IconsList.plane} <span className="icon-copy">AIR</span>
            </div>
        </div>
    </Container>
  );
};

SecondaryHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.object,
};





export default SecondaryHero;

export { SecondaryHero };



export const query = graphql`
  fragment secondaryHeroIcon on File {
    childImageSharp {
        fixed(width: 70, height: 70, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
  }
`;