import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby"

import { Header, Footer } from './index';
import GlobalStyles from "../../styles/globals";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles />
      <div style={{ display: "none" }}>
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/f240e5fc-3d8d-4127-a55b-6c6c5dacb8de/cd.js" type="text/javascript" async />
      </div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  
};

Layout.defaultProps = {
  wallet: false,
}

export default Layout;
