import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brand } from '../../../styles';
import { 
    faPlane,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faArrowUpRightFromSquare
   } from '@fortawesome/free-solid-svg-icons';

const IconsList = {
    "plane": <FontAwesomeIcon icon = { faPlane } size="1x" inverse/>,
    "rightArrow": <img src="./assets/icons/arrow-right.png" />,
    "chevronRight": <FontAwesomeIcon icon= { faChevronRight } size="1x" />,
    "chevronLeft": <FontAwesomeIcon icon = { faChevronLeft } size="1x" />,
    "chevronDown": <FontAwesomeIcon icon= { faChevronDown } size="1x" />,
    "externalBlue" : <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginLeft: "10px", color: brand.colors.pg_blue}}/>,
    "externalOrange": <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginLeft: "10px", color: brand.colors.pg_orange}}/>,
    "external": <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginLeft: "10px", color: "#fff"}}/>
}

export default IconsList;