/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { brand, mq } from "../../../styles";
import styled, { css } from "styled-components";

const ContentElement = styled.section`
  padding: 0;
  position: relative;
  line-height: 32px;
  background-color: ${brand.colors.pg_primary_dark};
  color: #fff;
  width: 100%;
  margin: 0 auto;

  ${props =>
    props.whiteBG &&
    css`
      background-color: #fff;
    `}

  ${props =>
    props.secondaryLightBG &&
    css`
      background-color: ${brand.colors.pg_primary_light};
    `}

  ${props =>
    props.orangeBG &&
    css`
      background-color: ${brand.colors.pg_orange};
    `}

  ${props =>
    props.noMargin &&
    css`
      margin: 0;
      margin-bottom: -26px;
      padding-bottom: 26px;
    `}

  ${props =>
    props.e2e &&
    css`
      margin: 0;
      margin-top: 0px;
      padding-top: 20px;
      padding-bottom: 26px;
    `}

  ${props =>
    props.noSpace &&
    css`
      padding: 75px 0px;
      margin: 0;

      @media screen and ${mq.maxMd} {
        padding: 50px 0px 0px 0px;
      }
    `}

  ${props =>
    props.form &
    css`
      .container {
        padding-bottom: 75px;
      }
    `}

  .container {
    width: 100%;
    
    color: #fff;
  }
  ${props =>
    props.bgDarkBlue &&
    css`
      width: 95%;
      max-width: 1400px;
      margin: auto;
      .container {
        margin: 50px auto;
        line-height: 1.5rem;
        @media screen and ${mq.maxLg} {
          margin: auto;
          max-width: 90%;
        }
        h3 {
          font-family: "Sherika", Helvetica Neue, sans-serif;
          font-weight: 700;
          font-size: 48px;
          text-align: left;
          color: #1b64ef;
          @media screen and ${mq.maxSm} {
            font-size: 28px;
            text-align: center;
          }
        }
        p {
          @media screen and ${mq.maxSm} {
            text-align: center;
          }
        }
      }
    `}
  ${props =>
    props.aboutContentImageBlock &&
    css`
      background-color: #fff;
      margin-bottom: -12px;
      .container {
        max-width: 1100px;
        width: 98%;
        display: flex;
        @media screen and ${mq.maxMd} {
          display: block;
          width: 100%;
        }
        h3 {
          color: #f74e01;
          font-size: 40px;
        }
        p {
          color: #000414;
          font-size: 23px;
          font-weight: bold;
        }
        .left {
          margin: auto auto auto 0;
          width: 40%;
          @media screen and (max-width: 1200px) {
            margin: auto auto auto 5%;
          }
          @media screen and ${mq.maxMd} {
            width: 100%;
            margin: auto;
            padding: 10% 2%;
            text-align: center;
          }
        }

        .aboutBg.gatsby-image-wrapper {
          // position: absolute !important;
          filter: grayscale(1) contrast(1.2);

          &:after {
            content: "";
            position: absolute;
            z-index: 350;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              0deg,
              rgba(0, 3, 31, 0.2),
              rgba(0, 0, 30, 0.2)
            );
          }
        }
      }
    `}
  ${props =>
    props.bgaboutWhite &&
    css`
      background-color: #fff;
      padding: 60px 0px 50px;
      .container {
        max-width: 1400px;
        width: 98%;
        @media screen and ${mq.maxMd} {
          width: 100%;
        }
        .whiteBgContents {
          width: 100%;
          text-align: center;
          margin: auto;
          h2 {
            color: #f74e01;
            font-weight: 700;
            text-align: center;
            font-size: 56px;
          }
          p {
            color: black;
          }
        }
      }
    `}
`;

/**
 * This is our basic content container element.
 */
const Content = ({ children, contained, ...props }) => (
  <ContentElement {...props}>
    {contained ? <div className="container">{children}</div> : { children }}
  </ContentElement>
);

Content.propTypes = {
  contained: PropTypes.bool,
};

Content.defaultProps = {
  contained: true,
};

export default Content;
