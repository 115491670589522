import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withPrefix, graphql, useStaticQuery } from "gatsby";
import styled from 'styled-components';
import { getWalletUrls } from "../../../utils";
import { brand, mq } from "../../../styles";
import english from "../../../../content/translations/en-CA/footermenu.json"
import french from "../../../../content/translations/fr-CA/footermenu.json"
import Logo from '../Header/Logo';
import { faChevronRight, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const basePath = withPrefix('/social/');
const currentYear = new Date().getFullYear();

const FooterWrapper = styled.footer`
  background: #000414;
  font-size: 14px;
  position: relative;
  z-index: 300;
  color: white;
  width: 98%;
    max-width: 1400px;
    margin: 5% auto 1% auto;

    .points-footer-logo{
      @media screen and (min-width: 992px) and (max-width: 1234px) {
        margin-left: -10px;
      }
      @media screen and (max-width: 991px)  {
        margin-left: -20px;
        width: 100%;
      }
    }
  @media screen and (max-width: 1017px) {
    font-size: 14px;
    margin: 5% 3%;
  }
  @media screen and (max-width: 600px) {
    margin: 5% 0% 5% 3%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    color: #F74E01;
    text-decoration: none;

    &[target="_blank"]:after {
      display: none;
    }
  }

  .column {
    border-radius: 10px; 
      padding: 15px 20px;

      @media screen and (max-width: 900px) {
        padding: 15px 0px;
      }
      &.products-column{
        width: 22%;
        @media screen and (max-width: 1100px) {
          width: 26%;
        }
        @media screen and (max-width: 900px) {
          width: 100%;
        }
      }
      &.verticals-column{
        width: 18%;
        @media screen and (max-width: 1100px) {
          width: 15%;
        }
        @media screen and (max-width: 900px) {
          width: 100%;
        }
      }
      &.about-column{
        width: 22%;
        @media screen and (max-width: 1100px) {
          width: 26%;
        }
        @media screen and (min-width: 900px) and (max-width: 991px) {
          width: 28%;
        }
        @media screen and (max-width: 900px) {
          width: 100%;
        }
      }
    &.contact-column{
      width: 40%;
      padding: 15px 0px;
      @media screen and (max-width: 1100px) {
        width: 33%;
      }
      @media screen and (min-width: 900px) and (max-width: 991px) {
        width: 30%;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    .points-footer-logo{
      .points-logo{
        height: 75px;
        float:right;
      }
    }
    h6 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    
  }

  .footer-legal {
    font-size: 12px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color:  #808080;

    border-top: 1px solid rgba(#000, 0.2);
    margin-bottom: 0;
    @media screen and (max-width: 761px) {
      padding-top: 0;
      // padding-left: 1rem;
    }
    @media screen and (min-width: 900px) {
      text-align: left;
      display: flex;
      gap: 15px;
    }
    
    p {
      margin-bottom: 0;
    }

    ul,
    li {
      display: inline-block;
      margin-left: 5px;
      @media screen and (max-width: 610px) {
        margin-left: 0px;
      }
    }

    li a {
      margin-left: 0;
      margin-right: 10px;
      @media screen and (min-width: 700px) {
        margin-left: 10px;
        margin-right: 0;
      }
    }
    div > a{
       color:  #808080;
       padding-bottom: 2px;
       border-bottom: 1px solid #808080;
    }
  }

  ul.social-nav {
    float: none;
    display: block;
    padding-top: 1rem;
    @media screen and (max-width: 610px) {
      display: flex;
      justify-content: space-between;
    }
    li {

      &:not(:last-child) {
        margin-right: 1rem;
        @media screen and (min-width: 700px) {
          margin-right: 0;
        }
      }
    }
  }

  .footer-menu {
    display : flex;
    justify-content: flex-start;

    &:after {
      content: '';
      display: block;
      clear: both;
    }
    @media screen and (max-width: 900px) {
     display: block;
    }
  }

  .footer-logos {
    margin: 2rem auto;

    img {
      height: 100px;
      opacity: .5;
    }
  }

  .social-nav {
    li {
      a {
        opacity: .8;
        display: inline-block;
        height: 17px;
        width: 17px;
      }

      &:hover a {
        opacity: 1;
      }

    }
  }

  .breadcrumbs {
    padding: 5px 0;
    color: rgba(#fff, 0.3);
    background: darken(#286FF7, 25%);

    a {
      color: white;
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }

    }
  }
`;

const Footer = () => {
  const [signInUrl, setSignInUrl] = useState('');

  useEffect(() => {
    setSignInUrl(getWalletUrls().signInUrl);
  }, []);

  const isFrench = typeof window != 'undefined' ? window.location.href.indexOf('/fr') > -1 ? true: false: null;
  const isHospitality = typeof window != 'undefined' ? window.location.href.indexOf('/hospitality') > -1 || window.location.href.indexOf('/hotellerie') > -1 ? true: false: null;

  const Footerlogo = useStaticQuery(graphql`
  query {
    footer_logo: file(base: {eq: "Footer_logo.png" }){
      childImageSharp {
        fixed(width: 140, height: 140, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
  `);
  return (
    <>
      <FooterWrapper>
       
      <div className="points-footer-logo" style={{marginBottom: "10px"}}>
      <Logo logo = {Footerlogo['footer_logo']} className="Hello"/>
      </div>
        <div className="container footer-menu">
        
        <div className="column contact-column">
        <h6>{ isFrench ? 'Contactez-nous' : 'Contact Us'}</h6>
        <div>{ isFrench ? 'Pour les requêtes liées à la facturation ou au support à la clientèle' : 'For billing or customer support inquiries:'}</div>
        <div style={{fontWeight: 'bold', marginBottom: '15px'}}>+1. 361. 666. 3617</div>
        <div>{isFrench ? 'Pour toutes autres requêtes' : 'For all other inquiries:'}</div>
        <div style={{fontWeight: 'bold'}}>+1.514.437.2185</div>
          </div>
          <div className="column products-column">
            <h6>{ isFrench ? 'Produits' : 'Products'}</h6>
            <ul className="footer-list products-footer-list">
              <li><Link to={isFrench ? '/fr/produits#premium-upgrade': '/products#premium-upgrade'}>{isFrench ? 'Surclassement': 'Premium Upgrade'} <FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/> </Link> </li>
              <li><Link to={isFrench ? '/fr/produits#dynamic-seat-blocker': '/products#dynamic-seat-blocker'}>{ isFrench? 'Sièges en supplément': 'Seat Blocker'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><Link to={isFrench ? '/fr/produits#speed-pass': '/products#speed-pass'}>{isFrench ? 'Billet rapide': 'SpeedPass'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              
              <li><Link to={isFrench ? '/fr/industries/hotellerie/upgrade': '/industries/hospitality/upgrade'}>{isFrench ? 'Premium Upgrade pourl’industrie de l’hôtellerie': 'Premium Upgrade for Hospitality'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>


              <li><Link to={isFrench ? '/fr/industries/hotellerie/stayextend': '/industries/hospitality/stayextend'}>{isFrench ? 'StayExtend': 'StayExtend'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><Link to={isFrench ? '/fr/industries/hotellerie/stayplus': '/industries/hospitality/stayplus'}>{isFrench ? 'StayPlus': 'StayPlus'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><a href='https://www.points.com'target="_blank" rel="noreferer">{ isFrench ? 'Solutions de fidélisation par Points': 'Loyalty Solutions by Points'}<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{paddingLeft: '8px', height: '12px'}}/></a></li>
            </ul>
          </div>
          <div className="column verticals-column">
            <h6>Industries</h6>
            <ul className="footer-list learn-footer-list">
              <li><Link to={ isFrench ? '/fr/industries/transport-aerien/' : '/industries/air/'}>{ isFrench ? 'Transport aérien': 'Air'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link> </li>
              <li><Link to={ isFrench ? '/fr/industries/hotellerie/' : '/industries/hospitality/'}>{ isFrench? 'Hôtellerie' : 'Hospitality'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><Link to={ isFrench ? '/fr/industries/croisiere/' : '/industries/cruise/'}>{ isFrench? 'Croisière' : 'Cruise'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><Link to={ isFrench ? '/fr/industries/rail/' : '/industries/rail/'}>{ isFrench? 'Rail' : 'Rail'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><a target="_blank" rel="noreferer" href='https://www.points.com' >{ isFrench ? 'Fidélisation' : 'Loyalty Solutions'}<FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{paddingLeft: '8px', height: '12px'}}/></a></li>
            </ul>
          </div>
          <div className="column about-column">
            <h6>{isFrench ? 'À propos de Plusgrade' : 'About Plusgrade'}</h6>
            <ul className="footer-list about=footer-list">
              <li><Link to={ isFrench ? '/fr/a-propos-de/' : '/about/'}>{ isFrench ? 'À propos de ' : 'About'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><Link to={ isFrench ? '/fr/a-propos-de/equipe-de-direction/' : '/about/executive-lead-team/'}>{ isFrench ? 'Équipe de direction' : 'Executive Leadership Team'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><Link to={ isFrench ? '/fr/carrieres/' : '/careers/'}>{ isFrench ? 'Joignez notre équipe' : 'Join Our Team'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
              <li><Link to={ isFrench ? '/fr/contact/' : '/contact/'}>{ isFrench ? 'Contactez-nous' : 'Contact Us'}<FontAwesomeIcon icon={faChevronRight} style={{paddingLeft: '8px', height: '12px'}}/></Link></li>
            </ul>
          </div>
        </div>
        <ul className="social-nav">
          <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/plusgrade/"
              >
                <img alt="linked in logo" src={`${basePath}linkedin.svg`} />
              </a>
            </li>
            {/* <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/pointsfans/"
              >
                <img alt="facebook logo" src={`${basePath}facebook.svg`} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/PointsLoyalty"
              >
                <img alt="twitter logo" height={17} width={17} src={`${basePath}twitter.svg`} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/pointsloyalty/"
              >
                <img alt="instagram logo" src={`${basePath}instagram.svg`} />
              </a>
            </li> */}
            
            
          </ul>
        <div className="container footer-legal" style={{marginTop: '3%'}}>
          <div className="copyrightNotice">
            &copy; {currentYear} {isFrench ? 'Plusgrade Inc. Tous droits réservés. Toutes les autres marques sont la propriété de leurs détenteurs respectifs.' : 'Plusgrade Inc. All rights reserved. All other marks are the property of their respective owners.'}
          </div>
          <div className="privacy">
          {isHospitality ? 
          // <Link to={ isFrench ? '/fr/politique/hotellerie-politique-de-confidentialite/' : '/policy/hospitality_privacy/'}>{ isFrench ? 'Politique de confidentialité' : 'Privacy policy'}</Link>
          <Link to={ isFrench ? '/fr/politique/politique-de-confidentialite/' : '/policy/privacy/'}>{ isFrench ? 'Politique de confidentialité' : 'Privacy policy'}</Link>
          : 
          <Link to={ isFrench ? '/fr/politique/politique-de-confidentialite/' : '/policy/privacy/'}>{ isFrench ? 'Politique de confidentialité' : 'Privacy policy'}</Link>

          }</div>
          <div className="cookie"><Link to={ isFrench ? '/fr/politique/politique-de-temoins/' : '/policy/cookie-policy/'}>{ isFrench ? 'Politique de témoins' : 'Cookie policy'}</Link></div>
          <div className="cookie"><Link to={ isFrench ? '/fr/politique/politique-de-divulgation-de-vulnérabilités/' : '/policy/vulnerability-disclose-program/'}>{ isFrench ? 'divulgation de vulnérabilités' : 'Vulnerability Disclosure'}</Link></div>
          {/* <ul>
            <li>
              <Link to={lang.footer.bottom.terms_link}>{lang.footer.bottom.terms_copy}</Link>
            </li>
            <li>
              <Link to={lang.footer.bottom.privacy_link}>{lang.footer.bottom.privacy_copy}</Link>
            </li>
            <li>
              <Link to={lang.footer.bottom.access_link}>{lang.footer.bottom.access_copy}</Link>
            </li>
            <li>
              <Link to={lang.footer.bottom.gdpr_link}>{lang.footer.bottom.gdpr_copy}</Link>
            </li>
          </ul> */}
          {/* <ul className="social-nav">
          <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/points"
              >
                <img alt="linked in logo" src={`${basePath}linkedin.svg`} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/pointsfans/"
              >
                <img alt="facebook logo" src={`${basePath}facebook.svg`} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/PointsLoyalty"
              >
                <img alt="twitter logo" height={17} width={17} src={`${basePath}twitter.svg`} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/pointsloyalty/"
              >
                <img alt="instagram logo" src={`${basePath}instagram.svg`} />
              </a>
            </li>
            
            
          </ul> */}
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
