import { Container, StyledLink } from "./index";
import React from "react";

const PrivacyPolicy = ({ policy, link, clickableText }) => {
  return (
    <Container>
      <span>{policy}</span>
      <StyledLink href={link} target="_blank">
        {clickableText}
      </StyledLink>
    </Container>
  );
};

export default PrivacyPolicy;
