import { css } from "styled-components";
import { withPrefix } from "gatsby";

let basePath = '/fonts/';

try {
  basePath = withPrefix('/fonts/');
} catch (error) {
  // ReferenceError: __BASE_PATH__ is not defined
  // console.log(error);
}

const nunito = css`

@font-face {
	font-family: 'Nunito';
	font-style: normal;
	font-display: swap;
	src: url(${basePath}nunito/NunitoSans-Regular.ttf);
}

@font-face {
	font-family: 'Nunito';
	font-weight: 400;
	font-style: italic;
	font-display: swap;
	src: url(${basePath}nunito/NunitoSans-Italic.ttf);
	
}

@font-face {
	font-family: 'Nunito';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: url(${basePath}nunito/NunitoSans-Bold.ttf);
	
}

@font-face {
	font-family: 'Nunito';
	font-weight: 900;
	font-style: normal;
	font-display: swap;
	src: url(.${basePath}greycliff/NunitoSans-Black.ttf);
}
`;

export default nunito;
